import produce from 'immer';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';

import { SessionId } from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import { Point } from 'common/models/geometry.interface';
import {
  ControlChangeEvent,
  IncompleteRemoteIagoEvent,
  LocalIagoEvent,
  RemoteIagoEvent,
  isControlChangeEvent,
  isCursorChangeEvent,
  isCursorDataRepresentationMultiple,
  isCursorDataRepresentationSingleDpiAware,
} from 'common/models/iago-event.interface';
import { BufferProxy } from 'common/utils/buffer-proxy';
import {
  CursorUrlRepresentation,
  isCursorUrlRepresentationMultiple,
  isCursorUrlRepresentationSingleDpiAware,
} from 'pages/vo/vo-react/features/iago/iago-state.types';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';
import { useObservable } from 'utils/react/use-observable';

import { createGlobalAction } from '../../redux/create-slice';

export const broadcastEvent = createGlobalAction<{
  sessionId: SessionId;
  event: IncompleteRemoteIagoEvent;
}>('broadcastEvent');
export const onIagoLocalEvent = createGlobalAction<LocalIagoEvent>('onIagoEvent');
export const toggleBroadcastKeyboardEventsToTrackId = createGlobalAction<{
  isEnabled: boolean;
  win: Window;
  trackId: string;
}>('toggleBroadcastKeyboardEventsToTrackId');

// In dev, we can optionally buffer these broadcast to make testing easier with yourself.
export const iagoBroadcastBufferProxy = new BufferProxy();

// Key state by host PeerId for multi-share reasons: e.g. Alice shares two
// monitors with Betty, Betty's guest cursor can only be in control on one of
// the screens at a time, so trackId is not the right differentiator.
let iagoState: {
  inControlPeerIdByHostPeerId: {
    [hostPeerId: string]: {
      inControlPeerId: AnyPeerId;
    };
  };
  cursorByPeerId: {
    [peerId: string]: {
      position?: Point;
      // trackId is needed to differentiate which track the cursor was last
      // updated on, in the case of multiple screen shares from the same host.
      trackId?: string;
      cursorRepresentation?: CursorUrlRepresentation;
    };
  };
} = { inControlPeerIdByHostPeerId: {}, cursorByPeerId: {} };
const iagoState$ = new BehaviorSubject(iagoState);
exposeToGlobalConsole({ iagoState$ });

const notifyIagoStateUpdate = () => {
  iagoState$.next(iagoState);
};

const ensureHostState = (state: typeof iagoState, hostPeerId: AnyPeerId) => {
  if (!state.inControlPeerIdByHostPeerId[hostPeerId]) {
    state.inControlPeerIdByHostPeerId[hostPeerId] = {
      inControlPeerId: hostPeerId,
    };
  }
};

const ensureCursorState = (state: typeof iagoState, cursorPeerId: AnyPeerId) => {
  if (!state.cursorByPeerId[cursorPeerId]) {
    state.cursorByPeerId[cursorPeerId] = {};
  }
};

export const renderIagoEvent = ({
  event,
  eventPeerId,
  hostPeerId,
}:
  | {
      event: RemoteIagoEvent | LocalIagoEvent;
      eventPeerId: AnyPeerId;
      hostPeerId: AnyPeerId;
    }
  | { event: ControlChangeEvent; eventPeerId: undefined; trackId: undefined; hostPeerId: AnyPeerId }) => {
  iagoState = produce(iagoState, (draft) => {
    if (isControlChangeEvent(event)) {
      ensureHostState(draft, hostPeerId);

      const oldInControlPeerId = draft.inControlPeerIdByHostPeerId[hostPeerId].inControlPeerId;
      const newInControlPeerId = event.peerId;

      draft.inControlPeerIdByHostPeerId[hostPeerId].inControlPeerId = newInControlPeerId;
      ensureCursorState(draft, newInControlPeerId);

      // Swap cursors
      if (oldInControlPeerId) {
        if (draft.cursorByPeerId[oldInControlPeerId]?.cursorRepresentation) {
          draft.cursorByPeerId[newInControlPeerId].cursorRepresentation =
            draft.cursorByPeerId[oldInControlPeerId].cursorRepresentation;
          delete draft.cursorByPeerId[oldInControlPeerId].cursorRepresentation;
        }
      }
    } else {
      if (isCursorChangeEvent(event)) {
        ensureHostState(draft, hostPeerId);
        const inControlPeerId = draft.inControlPeerIdByHostPeerId[hostPeerId].inControlPeerId;
        ensureCursorState(draft, inControlPeerId);
        const cursorRepresentation = draft.cursorByPeerId[inControlPeerId].cursorRepresentation;
        if (cursorRepresentation) {
          if (isCursorUrlRepresentationSingleDpiAware(cursorRepresentation))
            URL.revokeObjectURL(cursorRepresentation.singleDpiAwareRepresentation.url);
          else if (isCursorUrlRepresentationMultiple(cursorRepresentation))
            cursorRepresentation.multipleRepresentations.forEach(({ url }) => URL.revokeObjectURL(url));
        }
        if (isCursorDataRepresentationSingleDpiAware(event)) {
          draft.cursorByPeerId[inControlPeerId].cursorRepresentation = {
            cursorType: 'single-dpi-aware-representation',
            singleDpiAwareRepresentation: {
              hotspot: event.singleDpiAwareRepresentation.hotspot,
              url: URL.createObjectURL(
                new Blob([new Uint8Array(event.singleDpiAwareRepresentation.data)], { type: 'image/png' }),
              ),
            },
          };
        } else if (isCursorDataRepresentationMultiple(event)) {
          draft.cursorByPeerId[inControlPeerId].cursorRepresentation = {
            cursorType: 'multiple-representations',
            multipleRepresentations: event.multipleRepresentations.map(({ data, hotspot }) => ({
              hotspot,
              url: URL.createObjectURL(new Blob([new Uint8Array(data)], { type: 'image/png' })),
            })),
          };
        }
      } else {
        if (!eventPeerId) return;
        ensureCursorState(draft, eventPeerId);
        draft.cursorByPeerId[eventPeerId].position = event.location;
        draft.cursorByPeerId[eventPeerId].trackId = event.trackId;
      }
    }
  });
  notifyIagoStateUpdate();
};

export const useCursorState = ({ trackId, peerId }: { trackId: string; peerId: AnyPeerId }) =>
  useObservable<typeof iagoState['cursorByPeerId'][string] | undefined>(
    () =>
      iagoState$.pipe(
        map((state) => state.cursorByPeerId[peerId]),
        // If it's the self peer, we don't need to worry about trackIds, since
        // we will have the same cursor on all tracks. Confirm this is true when
        // we have multiple screen shares in the same session.
        map((cursor) => (cursor?.trackId === trackId || peerId === 'self' ? cursor : undefined)),
        distinctUntilChanged(),
      ),
    undefined,
    [peerId],
  );

export const cleanupHostState = ({ hostPeerId }: { hostPeerId: AnyPeerId }) => {
  iagoState = produce(iagoState, (draft) => {
    delete draft.inControlPeerIdByHostPeerId[hostPeerId];
  });
  notifyIagoStateUpdate();
};

export const cleanupPeerState = ({ peerId }: { peerId: AnyPeerId }) => {
  iagoState = produce(iagoState, (draft) => {
    const cursorRepresentation = draft.cursorByPeerId[peerId]?.cursorRepresentation;
    if (cursorRepresentation) {
      if (isCursorUrlRepresentationSingleDpiAware(cursorRepresentation))
        URL.revokeObjectURL(cursorRepresentation.singleDpiAwareRepresentation!.url);
      else if (isCursorUrlRepresentationMultiple(cursorRepresentation))
        cursorRepresentation.multipleRepresentations.forEach(({ url }) => URL.revokeObjectURL(url));
    }
  });
  notifyIagoStateUpdate();
};
