import jsonStableStringify from 'fast-json-stable-stringify';
import { isEqual, keyBy, mapValues, size } from 'lodash';

import { ScreenInfo } from 'common/models/db/user.interface';
import { Rectangle } from 'common/models/geometry.interface';
import { getZeroPoint } from 'common/utils/geometry-utils';
import { isDesktopApp } from 'utils/client-utils';
import { isMac } from 'utils/react/user-agent';

import { createSlice } from '../../redux/create-slice';
import { leaveSession } from '../sessions/sessions.slice';

export type ScreensSlice = {
  sharedScreen?: { id: string; rect: Rectangle };
  // N.B. This can't be in component state because a user might be choosing a
  // mode and switch the window type to compact, for example.
  isChoosingScreenSelectionMode: boolean;
  screenSelectionMode?: 'partial' | 'full';
  partialShareRectById: { [id: string]: Rectangle };
  allScreensById: { [id: string]: ScreenInfo };
  primaryScreenId?: string;
};

const fakeBrowserPartialScreenSlice = {
  primaryScreenId: 'primary',
  allScreensById: {
    primary: {
      bounds: {
        origin: getZeroPoint(),
        size: {
          width: document.body.clientWidth,
          height: document.body.clientHeight,
        },
      },
      scaleFactor: devicePixelRatio,
      screenId: 'primary',
      sourceId: 'primary',
      workArea: {
        origin: getZeroPoint(),
        size: {
          width: document.body.clientWidth,
          height: document.body.clientHeight,
        },
      },
    },
  },
};

const initialState: ScreensSlice = {
  isChoosingScreenSelectionMode: false,
  partialShareRectById: {},
  allScreensById: {},
  ...(!isDesktopApp && fakeBrowserPartialScreenSlice),
};
export const { createReducer, createSelector, createMemoizedSelector, createAction, createThunk } =
  createSlice('screens', initialState, { persistKeys: ['partialShareRectById'] });

export const shareFullScreen = createThunk('shareFullScreen', (dispatch, getState, id: string) =>
  dispatch(shareScreen({ id, rect: getScreenBounds(getState(), id)! })),
);
export const sharePartialScreen = createThunk('sharePartialScreen', (dispatch, getState, id: string) =>
  dispatch(
    shareScreen({
      id,
      rect: getPartialShareRectForId(getState(), id) ?? getScreenBounds(getState(), id)!,
    }),
  ),
);

export const setScreenSelectionMode = createThunk(
  'setScreenSelectionMode',
  (dispatch, getState, mode: 'partial' | 'full' | undefined) => {
    const numberOfScreens = getNumberOfScreens(getState());

    dispatch(internalSetScreenSelectionMode(mode));

    if (mode === 'full' && numberOfScreens === 1) {
      dispatch(shareFullScreen(getFirstScreenId(getState())));
    }
  },
);

export const shareScreen = createAction<{ id: string; rect: Rectangle }>('shareScreen');
export const stopSharingScreen = createAction('stopSharingScreen');
export const setIsChoosingScreenSelectionMode = createAction<boolean>('setIsChoosingScreenSelectionMode');
const internalSetScreenSelectionMode = createAction<'partial' | 'full' | undefined>(
  'internalSetScreenSelectionMode',
);
export const setPartialShareRectForId = createAction<{ id: string; rect: Rectangle }>('setPartialShareRect');
export const updateScreensFromDesktop =
  createAction<{ screens: ScreenInfo[]; primaryId: string }>('updateScreensFromDesktop');

export default createReducer()
  .on(shareScreen, (state, { payload }) => {
    state.sharedScreen = payload;
    delete state.screenSelectionMode;
  })
  .on(stopSharingScreen, (state) => {
    delete state.sharedScreen;
  })
  .on(setIsChoosingScreenSelectionMode, (state, { payload: isChoosingScreenSelectionMode }) => {
    state.isChoosingScreenSelectionMode = isChoosingScreenSelectionMode;
  })
  .on(setPartialShareRectForId, (state, { payload: { id, rect } }) => {
    state.partialShareRectById[id] = rect;
  })
  .on(internalSetScreenSelectionMode, (state, { payload: screenSelectionMode }) => {
    if (screenSelectionMode) state.screenSelectionMode = screenSelectionMode;
    else delete state.screenSelectionMode;
    state.isChoosingScreenSelectionMode = false;
  })
  .on(updateScreensFromDesktop, (state, { payload: { screens, primaryId } }) => {
    state.allScreensById = keyBy(screens, 'screenId');
    state.primaryScreenId = primaryId;
  })
  .on(leaveSession, (state) => {
    delete state.sharedScreen;
    delete state.screenSelectionMode;
    state.isChoosingScreenSelectionMode = false;
  });

export const getAllScreensKeyedById = createSelector((state) => state.screens.allScreensById);
export const getFirstScreenId = createMemoizedSelector(
  getAllScreensKeyedById,
  (allScreensKeyedById) => Object.keys(allScreensKeyedById)[0],
);
export const getScreenArrangement = createSelector((state) => {
  const allScreenBounds = getAllScreenBoundsById(state);
  if (!size(allScreenBounds)) return undefined;
  return jsonStableStringify(allScreenBounds);
});

export const getScreenInfoForId = createMemoizedSelector(
  getAllScreensKeyedById,
  (state: { screens: ScreensSlice }, id?: string) => id,
  (allScreensById, id) => (id ? allScreensById[id] : undefined),
);
export const getScreenBounds = createMemoizedSelector(getScreenInfoForId, (screen) => screen?.bounds, {
  memoizeOptions: {
    resultEqualityCheck: isEqual,
  },
});
export const getAllScreenBoundsById = createMemoizedSelector(
  getAllScreensKeyedById,
  (allScreensById) => mapValues(allScreensById, ({ bounds }) => bounds),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const getScreenSelectionMode = createSelector((state) => state.screens.screenSelectionMode);
export const getPartialShareRectForId = createSelector(
  (state, id: string): Rectangle | undefined => state.screens.partialShareRectById[id],
);

export const getSharedScreenIdAndRect = createSelector((state) => state.screens.sharedScreen);
export const getIsScreenShared = createSelector((state) => !!getSharedScreenIdAndRect(state));
export const getSharedScreenId = createSelector((state) => state.screens.sharedScreen?.id);
export const getSharedScreenRect = createSelector((state) => state.screens.sharedScreen?.rect);
export const getScreenInfoForSharedScreen = createSelector((state) =>
  getScreenInfoForId(state, state.screens.sharedScreen?.id),
);
export const getScreenBoundsForSharedScreen = createSelector(
  (state) => getScreenInfoForSharedScreen(state)?.bounds,
);
export const getIsChoosingScreenSelectionMode = createSelector(
  (state) => state.screens.isChoosingScreenSelectionMode,
);

export const getPrimaryScreenId = createSelector(
  (state) => state.screens.primaryScreenId ?? getFirstScreenId(state),
);

export const getPrimaryScreen = createSelector((state) =>
  getScreenInfoForId(state, getPrimaryScreenId(state)),
);

export const getPrimaryScreenMenuBarHeight = createSelector((state) => {
  if (!isMac) return 24;
  const screen = getPrimaryScreen(state);
  if (!screen) return 0;
  return screen?.workArea.origin.y - screen?.bounds.origin.y;
});

export const getPrimaryScreenHasNotch = createMemoizedSelector(getPrimaryScreenMenuBarHeight, (height) => {
  if (!isMac) return false;
  return height === 38;
});

export const getNumberOfScreens = createMemoizedSelector(getAllScreensKeyedById, (allScreensById) =>
  size(allScreensById),
);

// export const getDpiNormalizedScreenBoundsById = createSelector((state, id: string) => {
//   scaleSize(state.screens.allScreensById[id].bounds,
// })
