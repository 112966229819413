import { makeStateMachineDefinition } from 'utils/state-machine';

export const makeHudUiStateMachineDefinition = (idleTimerMs: number = 4000) => {
  let timerHandle: ReturnType<typeof setTimeout>;
  const debounceTimer = (fn: () => any) => {
    clearTimeout(timerHandle);
    timerHandle = setTimeout(fn, idleTimerMs);
  };
  const clearTimer = () => clearTimeout(timerHandle);

  return makeStateMachineDefinition({
    initialState: {
      value: 'collapsed',
      context: { pauseAutoCollapse: false, isMousedOverDuringPause: false },
    },
    states: {
      collapsed: {
        on: {
          'mouseover-collapsed': {
            next: 'expanded',
          },
          notify: {
            next: 'notification-expanded',
          },
        },
      },
      expanded: {
        on: {
          'mouseout-timer-fired': {
            next: 'collapsed',
            effect: ({ context }) => {
              if (context.pauseAutoCollapse) return false;
            },
          },
          notify: {
            next: 'expanded-and-notification-expanded',
          },
          'mouseover-collapsed': {
            effect: ({ context }) => {
              clearTimer();
              context.isMousedOverDuringPause = true;
            },
          },
          'mouseover-expanded': {
            effect: ({ context }) => {
              clearTimer();
              context.isMousedOverDuringPause = true;
            },
          },
          mouseout: {
            effect: ({ send, context }) => {
              debounceTimer(() => send('mouseout-timer-fired'));
              context.isMousedOverDuringPause = false;
            },
          },
          'pause-auto-collapse': {
            effect: ({ context }) => {
              context.pauseAutoCollapse = true;
              clearTimer();
            },
          },
          'resume-auto-collapse': {
            effect: ({ send, context }) => {
              context.pauseAutoCollapse = false;
              if (!context.isMousedOverDuringPause) {
                send('mouseout');
              }
            },
          },
          'force-collapse': {
            next: 'collapsed',
            effect: ({ context }) => {
              context.pauseAutoCollapse = false;
              clearTimer();
            },
          },
        },
      },
      'notification-expanded': {
        on: {
          'stop-notify': {
            next: 'collapsed',
          },
          'mouseover-collapsed': {
            next: 'expanded-and-notification-expanded',
            effect: clearTimer,
          },
          'mouseover-expanded': {
            next: 'expanded-and-notification-expanded',
            effect: clearTimer,
          },
        },
      },
      'expanded-and-notification-expanded': {
        on: {
          'mouseout-timer-fired': {
            next: 'notification-expanded',
            effect: ({ context }) => {
              if (context.pauseAutoCollapse) return false;
            },
          },
          'stop-notify': {
            next: 'expanded',
          },
          'mouseover-collapsed': {
            effect: ({ context }) => {
              clearTimer();
              context.isMousedOverDuringPause = true;
            },
          },
          'mouseover-expanded': {
            effect: ({ context }) => {
              clearTimer();
              context.isMousedOverDuringPause = true;
            },
          },
          mouseout: {
            effect: ({ send, context }) => {
              debounceTimer(() => send('mouseout-timer-fired'));
              context.isMousedOverDuringPause = false;
            },
          },
          'pause-auto-collapse': {
            effect: ({ context }) => {
              context.pauseAutoCollapse = true;
              clearTimer();
            },
          },
          'resume-auto-collapse': {
            effect: ({ send, context }) => {
              context.pauseAutoCollapse = false;
              if (!context.isMousedOverDuringPause) {
                send('mouseout');
              }
            },
          },
          'force-collapse': {
            next: 'notification-expanded',
            effect: ({ send, context }) => {
              context.pauseAutoCollapse = false;
              clearTimer();
              send('mouseout-timer-fired');
            },
          },
        },
      },
    },
  });
};
