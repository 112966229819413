import { startOfDay, subDays } from 'date-fns';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { orderByChild, startAfter, endAt } from 'firebase/database';
import {
  compact,
  flatMap,
  isEqual,
  isUndefined,
  keys,
  map as lodashMap,
  merge as lodashMerge,
  omitBy,
  values,
} from 'lodash';
import { combineLatest, forkJoin, from, interval, merge, NEVER, Observable, of, timer } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  groupBy,
  map,
  mapTo,
  mergeMap,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators/tag';

import { DbEvent, DbEventInstance, EventInstanceId } from 'common/models/db/event.interface';
import { DbRingMessage, isDbMessageRing } from 'common/models/db/message-bus.interface';
import { SessionHistoryEntryId, SessionHistoryId } from 'common/models/db/session-history.interface';
import {
  InstantMeetingSessionInitiator,
  isEventInstanceSessionInitiator,
  isInstantMeetingSessionInitiator,
  JoinableSessionInitiator,
  SessionInitiatorId,
  SessionInitiatorType,
} from 'common/models/db/session-initiatior.interface';
import { UserInfoPublic } from 'common/models/db/user.interface';
import {
  MessageId,
  DbCalendarScheduledBlock,
  SessionId,
  OrgId,
  SpaceId,
  TeamId,
  UserId,
  UserStatus,
  DbSessionGroup,
  InstantMeetingId,
  RemotePeerId,
  DbPeer,
} from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import {
  ignoreElements,
  distinctUntilChangedDeep,
  filterIsTruthy,
  mapIsTruthy,
  ofAction,
  ofActionPayload,
  pairwiseDifference,
  pluck,
  refCountCreateDestroy,
  simpleRefCount,
  switchMapExists,
  switchMapIfTruthy,
  waitForBrowserIdle,
  waitUntilTruthy,
  filterIsFalsey,
} from 'common/utils/custom-rx-operators';
import { getIanaTimeZoneName } from 'common/utils/date-time';
import { getEventIdAndInstanceStartTsFromEventInstanceId } from 'common/utils/event-utils';
import { firebaseTruthyObjectToArray } from 'common/utils/firebase-utils';
import { $anyFixMe } from 'common/utils/ts-utils';
import { setIsSelfInactive } from 'pages/vo/vo-react/features/activity/activity.slice';
import {
  getAllSelfAndSelfTeamsUserCurrentSessionInitiatorAndOrgIdPairs,
  getAllSelfAndSelfTeamsUserIdAndOrgIdPairs,
  getAllSessionGroupIdAndOrgIdPairs,
} from 'pages/vo/vo-react/features/common/org-wide-selectors';
import { dbInstantMeetingCreatedOrUpdated } from 'pages/vo/vo-react/features/instant-meetings/instant-meetings.slice';
import {
  addOrUpdateOrg,
  addUserToOrg,
  getOrgById,
  getOrgUserCurrentJoinableSessionInitiatorsById,
  getPersonalOrgId,
  removeUserFromOrg,
  selfAddedOrgId,
  selfRemovedOrgId,
  setOrgUserCurrentJoinableSessionInitiators,
} from 'pages/vo/vo-react/features/orgs/orgs.slice';
import {
  createTeam,
  dbTeamCreatedOrUpdated,
  dbTeamDeleted,
  deleteTeam,
  updateTeam,
} from 'pages/vo/vo-react/features/teams/teams.slice';
import {
  addOrUpdateUser,
  getSelfUserId,
  getSelfUserStatus,
  getSelfUserStatusClearMs,
  getSelfUserStatusReason,
  getUserAvatarUrlById,
  getUserById,
  getUserDisplayNameById,
  getUserStatusById,
  getUserStatusReasonById,
  listenToUser,
  setSelfEmail,
  setSelfPersonalContactLink,
  setSelfUserId,
  setSelfUserStatusOverride,
  setUserStatus,
} from 'pages/vo/vo-react/features/users/users.slice';
import { EpicWithDeps } from 'pages/vo/vo-react/redux/app-store';
import { clientAssertExhaustedType, isLocalhost } from 'utils/client-utils';
import { isStorybook } from 'utils/client-utils';
import { debugCheck } from 'utils/debug-check';
import { getFirebaseApp, serverTimestamp } from 'utils/firebase-app';
import { db, RxFirebaseDbWrapper } from 'utils/firebase-db-wrapper-client';
import { getFloofConnection } from 'utils/floof-sdk/floof-sdk';

import { apiCall, apiResponseAsObservable } from '../api';
import {
  addCalendarIdForUserIdOrgIdPair,
  addScheduledBlockToCalendar,
  dbClobberScheduledBlockForCalendarId,
  dbClobberSettingsForCalendarId,
  dbEventCreatedOrUpdated,
  dbEventDeleted,
  getCalendarId,
  getScheduledBlockById,
  getUserAndOrgIdsFromCalendarIdSlow,
  listenToCalendarDay,
  listenToEvent,
  listenToEventInstance,
  removeScheduledBlock,
  setCalendarSettingsForCalendarId,
  unsetCalendarIdForUserIdOrgIdPair,
  updateTimeForScheduledBlock,
} from '../features/calendar/calendar.slice';
import { getTimeZoneOffsetForSelf } from '../features/common/get-time-zone-offset-for-self';
import {
  getHeadSessionIdForSessionInitiator,
  getIsCurrentSessionNonTeamLobby,
  getSessionGroupIdForSessionInitiator,
} from '../features/common/session-selectors';
import { getFeatureFlag } from '../features/feature-flags/feature-flags.slice';
import { getCurrentUrlParams } from '../features/navigation/navigation.utils';
import {
  dbAddOrUpdateRing,
  dbDeleteRing,
  userAcceptedRing,
  userDeclinedRing,
  userRangUser,
} from '../features/rings/rings.slice';
import {
  getNameForSessionGroupIdAndSessionId,
  listenToSessionGroup,
  sessionGroupCreatedOrUpdated,
  sessionGroupDeleted,
} from '../features/session-groups/session-groups.slice';
import {
  dbSessionHistoryCreated,
  dbSessionHistoryEntryCreatedOrUpdated,
  dbSessionHistoryEntryDeleted,
  listenToSessionHistory,
} from '../features/session-history/session-history.slice';
import {
  setSessionPeers,
  getSessionById,
  dbSetCurrentSessionHistoryIdForSession,
  getCurrentSessionHistoryIdForSession,
  utteranceTranscribed,
  userWaved,
  setStartTsForSession,
  addSession,
  listenToSession,
  removeSession,
  joinPotentiallyUnknownSessionById,
  joinKnownSession,
  dbSetSessionExists,
  dbSetSessionMediaServer,
  dbSetSessionIsLocked,
  setIsSelfPaused,
} from '../features/sessions/sessions.slice';
import { getCurrentSessionId } from '../features/sessions/sessions.slice';
import { SessionPeer } from '../features/sessions/sessions.types';
import {
  createSpace,
  dbSpaceCreatedOrUpdated,
  dbSpaceDeleted,
  deleteSpace,
  updateSpace,
} from '../features/spaces/spaces.slice';
import { wasRecentlyPresent } from '../features/users/users.utils';
import { setZoomIsAuthorized } from '../features/zoom/zoom.slice';
import { createSlice } from '../redux/create-slice';

const { createAction } = createSlice('firebase');

// Internal actions
const orgAddedTeamId = createAction<{ orgId: OrgId; teamId: TeamId }>('orgAddedTeamId');
const orgRemovedTeamId = createAction<{ orgId: OrgId; teamId: TeamId }>('orgRemovedTeamId');
const orgAddedSpaceId = createAction<{ orgId: OrgId; spaceId: SpaceId }>('orgAddedSpaceId');
const orgRemovedSpaceId = createAction<{ orgId: OrgId; spaceId: SpaceId }>('orgRemovedSpaceId');
const selfAddedMessageId = createAction<MessageId>('selfAddedMessageId');
const selfRemovedMessageId = createAction<MessageId>('selfRemovedMessageId');

export const selfUserInfoEpic: EpicWithDeps = () => {
  if (isStorybook) return NEVER;
  const auth = getAuth(getFirebaseApp());
  return new Observable<User | null>((obs) => onAuthStateChanged(auth, obs)).pipe(
    map((authUser) => ({ userId: authUser?.uid as UserId, email: authUser?.email as string })),
    mergeMap(({ userId, email }) => merge(of(setSelfUserId(userId)), of(setSelfEmail(email)))),
  );
};

export const selfAddedOrgIds: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(setSelfUserId),
    switchMapExists((userId) =>
      merge(
        db
          .from(`users/${userId as string}/readonly/orgIds`)
          .whenChild('added', 'removed')
          .pipe(
            map(({ key: orgId, eventType }) =>
              (eventType === 'added' ? selfAddedOrgId : selfRemovedOrgId)(orgId as OrgId),
            ),
          ),
        db
          .from(`users/${userId as string}/readonly/messageBus`)
          .whenChild('added', 'removed')
          .pipe(
            map(({ key: messageId, eventType }) =>
              (eventType === 'added' ? selfAddedMessageId : selfRemovedMessageId)(messageId as MessageId),
            ),
          ),
        db
          .from(`users/${userId as string}/readonly/linkages/zoom`)
          .whenChanged()
          .pipe(map(({ val }) => setZoomIsAuthorized(!!val))),
      ),
    ),
  );

export const internalSelfAddedOrg: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(selfAddedOrgId),
    mergeMap(({ payload: orgId }) =>
      merge(
        db
          .from(`orgs/${orgId as string}`)
          .whenChanged()
          .pipe(
            pluck('val'),
            filterIsTruthy(),
            map(({ name, iconUrl, inviteUrlId, externalLinkageType, personalOrgUserId }) =>
              addOrUpdateOrg({
                name,
                id: orgId,
                iconUrl,
                inviteUrlId,
                externalLinkageType,
                isPersonalOrg: !!personalOrgUserId,
              }),
            ),
          ),
        state$
          .pipe(
            map((state) => getOrgById(state, orgId)),
            filterIsTruthy(),
            take(1),
          )
          .pipe(
            mergeMap(() =>
              merge(
                db
                  .from(`orgs/${orgId as string}/teamIds`)
                  .whenChild('added', 'removed')
                  .pipe(
                    map(({ key: teamId, eventType }) =>
                      (eventType === 'added' ? orgAddedTeamId : orgRemovedTeamId)({
                        orgId,
                        teamId: teamId as TeamId,
                      }),
                    ),
                  ),
                db
                  .from(`orgs/${orgId as string}/spaceIds`)
                  .whenChild('added', 'removed')
                  .pipe(
                    map(({ key: spaceId, eventType }) =>
                      (eventType === 'added' ? orgAddedSpaceId : orgRemovedSpaceId)({
                        orgId,
                        spaceId: spaceId as SpaceId,
                      }),
                    ),
                  ),
                db
                  .from(`orgs/${orgId as string}/userIds`)
                  .whenChild('added', 'removed')
                  .pipe(
                    mergeMap(({ key: userId, eventType, val }) =>
                      eventType === 'added' && val.accountStatus !== 'deleted'
                        ? of(
                            addUserToOrg({
                              orgId,
                              userId: userId as UserId,
                            }),
                          )
                        : of(
                            removeUserFromOrg({
                              orgId,
                              userId: userId as UserId,
                            }),
                          ),
                    ),
                  ),
                interval(60 * 1000).pipe(
                  startWith(0),
                  waitForBrowserIdle(),
                  mapTo(getIanaTimeZoneName()),
                  distinctUntilChanged(),
                  tap(() =>
                    db
                      .from(
                        `orgs/${orgId as string}/userIds/${
                          getSelfUserId(state$.value)! as string
                        }/calendarSettings/ianaTimeZone`,
                      )
                      .set(getIanaTimeZoneName()),
                  ),
                  ignoreElements(),
                ),
              ),
            ),
          ),
      ).pipe(
        takeUntil(
          action$.pipe(
            ofAction(selfRemovedOrgId),
            filter(({ payload: removedOrgId }) => removedOrgId === orgId),
          ),
        ),
      ),
    ),
  );

export const internalOrgAddedTeam: EpicWithDeps = (action$) =>
  action$.pipe(
    ofAction(orgAddedTeamId),
    mergeMap(({ payload: { orgId, teamId } }) =>
      merge(
        db
          .from(`teams/${teamId as string}`)
          .query(endAt(orgId))
          .whenChanged()
          .pipe(
            map(({ val }) =>
              val
                ? dbTeamCreatedOrUpdated({
                    name: val.name,
                    id: teamId,
                    emoji: val.emoji,
                    userIds: firebaseTruthyObjectToArray(val.userIds) as UserId[],
                    sessionGroupId: val.sessionGroupId,
                    orgId,
                  })
                : dbTeamDeleted({ teamId, orgId }),
            ),
          ),
      ).pipe(
        takeUntil(
          action$.pipe(
            ofAction(orgRemovedTeamId),
            filter(({ payload: { teamId: removedTeamId } }) => removedTeamId === teamId),
          ),
        ),
      ),
    ),
  );

export const updateDbForTeam: EpicWithDeps = (action$, state$) =>
  merge(
    action$.pipe(
      ofAction(createTeam),
      switchMap(async ({ payload: { name, orgId, emoji, userIds } }) => {
        await apiCall('createTeam', {
          name,
          orgIds: [orgId],
          emoji,
          userIds,
        });
      }),
      ignoreElements(),
    ),
    action$.pipe(
      ofAction(updateTeam),
      switchMap(async ({ payload: { id, name, emoji, userIds } }) => {
        await apiCall('updateTeam', {
          teamId: id,
          name,
          emoji,
          userIds,
        });
      }),
      ignoreElements(),
    ),
    action$.pipe(
      ofAction(deleteTeam),
      switchMap(async ({ payload: { teamId } }) => {
        await apiCall('deleteTeam', {
          teamId,
        });
      }),
      ignoreElements(),
    ),
  );

export const internalOrgAddedSpace: EpicWithDeps = (action$) =>
  action$.pipe(
    ofAction(orgAddedSpaceId),
    mergeMap(({ payload: { orgId, spaceId } }) =>
      merge(
        db
          .from(`spaces/${spaceId as string}`)
          .query(endAt(orgId))
          .whenChanged()
          .pipe(
            map(({ val: space }) =>
              space
                ? dbSpaceCreatedOrUpdated({
                    name: space.name,
                    id: spaceId,
                    emoji: space.emoji,
                    sessionGroupId: space.sessionGroupId,
                    orgId,
                  })
                : dbSpaceDeleted({ spaceId, orgId }),
            ),
          ),
      ).pipe(
        takeUntil(
          action$.pipe(
            ofAction(orgRemovedSpaceId),
            filter(({ payload: { spaceId: removedTeamId } }) => removedTeamId === spaceId),
          ),
        ),
      ),
    ),
  );

export const updateDbForSpace: EpicWithDeps = (action$, state$) =>
  merge(
    action$.pipe(
      ofAction(createSpace),
      switchMap(async ({ payload: { name, orgId, emoji } }) => {
        await apiCall('createSpace', {
          name,
          orgIds: [orgId],
          emoji,
        });
      }),
      ignoreElements(),
    ),
    action$.pipe(
      ofAction(updateSpace),
      switchMap(async ({ payload: { id, name, emoji } }) => {
        await apiCall('updateSpace', {
          spaceId: id,
          name,
          emoji,
        });
      }),
      ignoreElements(),
    ),
    action$.pipe(
      ofAction(deleteSpace),
      switchMap(async ({ payload: { spaceId } }) => {
        await apiCall('deleteSpace', {
          spaceId,
        });
      }),
      ignoreElements(),
    ),
  );

export const listenToSessionGroupEpic: EpicWithDeps = (action$, state$) => {
  const refCount$ = action$.pipe(ofActionPayload(listenToSessionGroup), refCountCreateDestroy());
  const refCountPipe$ = refCount$.pipe(
    filter(({ type }) => type === 'create'),
    mergeMap(({ payload: { sessionGroupId, orgId } }) =>
      db
        .from(`sessionGroups/${sessionGroupId as string}`)
        .query(endAt(orgId))
        .whenChanged()
        .pipe(
          pluck('val'),
          startWith(undefined as DbSessionGroup | undefined),
          pairwise(),
          mergeMap(([prev, curr]) =>
            merge(
              !curr
                ? of(sessionGroupDeleted(sessionGroupId))
                : merge(
                    of(
                      sessionGroupCreatedOrUpdated({
                        sessionGroupId,
                        sessionGroup: {
                          sessionInitiator: curr.sessionInitiator,
                          orgIds: firebaseTruthyObjectToArray(curr.orgIds) as OrgId[],
                          mediaServerType: curr.mediaServerType,
                          temporarilyAllowedUserIds: (firebaseTruthyObjectToArray(
                            curr.temporarilyAllowedUserIds,
                          ) ?? []) as UserId[],
                          items: lodashMap(
                            firebaseTruthyObjectToArray(curr.sessionIds) as SessionId[],
                            (sessionId, idx) => ({
                              name: sessionId,
                              order: idx,
                              sessionType: 'walkie-talkie',
                              sessionId,
                              emoji: 'smile',
                            }),
                          ),
                        },
                      }),
                    ),
                    ...lodashMap(firebaseTruthyObjectToArray(curr.sessionIds), (sessionId) =>
                      of(
                        listenToSession({
                          payload: {
                            sessionId: sessionId as SessionId,
                            sessionInitiator: curr.sessionInitiator,
                          },
                          type: 'create',
                        }),
                      ),
                    ),
                  ),
              !prev
                ? NEVER
                : merge(
                    ...lodashMap(prev.sessionIds, (_session, sessionId) =>
                      of(
                        listenToSession({
                          payload: {
                            sessionId: sessionId as SessionId,
                            sessionInitiator: prev.sessionInitiator,
                          },
                          type: 'destroy',
                        }),
                      ),
                    ),
                  ),
            ),
          ),
          takeUntil(
            refCount$.pipe(
              filter(
                ({ payload, type }) => type === 'destroy' && isEqual(payload, { sessionGroupId, orgId }),
              ),
            ),
          ),
        ),
    ),
  );

  const listenToAllSessionGroupsFromStore$ = state$.pipe(
    map((state) => getAllSessionGroupIdAndOrgIdPairs(state)),
    distinctUntilChangedDeep(),
    pairwiseDifference(),
    mergeMap(([added, deleted]) =>
      merge(
        ...added.map(({ sessionGroupId, orgId }) =>
          of(
            listenToSessionGroup({
              payload: { sessionGroupId, orgId },
              type: 'create',
            }),
          ),
        ),
        ...deleted.map(({ sessionGroupId, orgId }) =>
          of(
            listenToSessionGroup({
              payload: { sessionGroupId, orgId },
              type: 'destroy',
            }),
          ),
        ),
      ),
    ),
  );

  return merge(refCountPipe$, listenToAllSessionGroupsFromStore$);
};

export const refCountListenToUserEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(listenToUser),
    simpleRefCount(
      {
        increment: ({ type }) => type === 'create',
        decrement: ({ type }) => type === 'destroy',
        groupBy: ({ payload: { userId } }) => userId,
      },
      ({ payload: { userId } }) =>
        db
          .from(`users/${userId as string}/public`)
          .whenChanged()
          .pipe(
            map(({ val }) => val!),
            filterIsTruthy(),
            map(({ displayName, avatarUrl, presentAvatarUrl, awayAvatarUrl }) =>
              addOrUpdateUser({
                displayName: displayName ?? '',
                id: userId,
                avatarUrl,
                presentUrl: presentAvatarUrl,
                awayUrl: awayAvatarUrl,
              }),
            ),
          ),
    ),
  );

const listenToOrgUser = createAction<{
  payload: { orgId: OrgId; userId: UserId };
  type: 'create' | 'destroy';
}>('listenToOrgUser');

export const emitListenToUserEpic: EpicWithDeps = (action$, state$) => {
  const emitListenToUserAndListenToOrgUser$ = state$.pipe(
    map((state) => getAllSelfAndSelfTeamsUserIdAndOrgIdPairs(state)),
    distinctUntilChangedDeep(),
    pairwiseDifference(),
    mergeMap(([added, deleted]) =>
      merge(
        ...added.map(({ orgId, userId }) =>
          merge(
            of(
              listenToUser({
                payload: { userId },
                type: 'create',
              }),
            ),
            of(listenToOrgUser({ payload: { orgId, userId }, type: 'create' })),
          ),
        ),
        ...deleted.map(({ orgId, userId }) =>
          merge(
            of(
              listenToUser({
                payload: { userId },
                type: 'destroy',
              }),
            ),
            of(listenToOrgUser({ payload: { orgId, userId }, type: 'destroy' })),
          ),
        ),
      ),
    ),
  );

  const refCount$ = action$.pipe(ofActionPayload(listenToOrgUser), refCountCreateDestroy());
  const setCurrentSessionInitiatorsForOrgUsers$ = refCount$.pipe(
    filter(({ type }) => type === 'create'),
    mergeMap(({ payload: { orgId, userId } }) =>
      // Only set an org user's currentSessionInitiators once we've added them to the store
      state$
        .pipe(
          map((state) => getOrgUserCurrentJoinableSessionInitiatorsById(state, orgId, userId)),
          filterIsTruthy(),
          take(1),
        )
        .pipe(
          switchMap(() =>
            db
              .from(`orgs/${orgId as string}/userIds/${userId as string}/currentJoinableSessionInitiators`)
              .whenChanged()
              .pipe(
                pluck('val'),
                map((currentJoinableSessionInitiators) =>
                  setOrgUserCurrentJoinableSessionInitiators({
                    orgId,
                    userId,
                    currentJoinableSessionInitiators: !currentJoinableSessionInitiators
                      ? []
                      : flatMap(
                          currentJoinableSessionInitiators,
                          (sessionInitiatorIdToSessionTruthyObject, sessionInitiatorType) =>
                            keys(sessionInitiatorIdToSessionTruthyObject).flatMap((id) =>
                              lodashMerge(
                                {
                                  type: sessionInitiatorType as SessionInitiatorType,
                                  id: id as SessionInitiatorId,
                                } as JoinableSessionInitiator,
                                ...(!sessionInitiatorIdToSessionTruthyObject
                                  ? [{}]
                                  : values(sessionInitiatorIdToSessionTruthyObject[id])),
                              ),
                            ),
                        ),
                  }),
                ),
              ),
          ),
          takeUntil(
            refCount$.pipe(
              filter(
                ({ payload: destroyPayload, type }) =>
                  type === 'destroy' && isEqual(destroyPayload, { orgId, userId }),
              ),
            ),
          ),
        ),
    ),
  );

  return merge(emitListenToUserAndListenToOrgUser$, setCurrentSessionInitiatorsForOrgUsers$);
};

export const addUserToOrgEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(addUserToOrg),
    mergeMap(({ payload: { userId, orgId } }) =>
      merge(
        db
          .from(`orgs/${orgId as string}/userIds/${userId as string}/calendarSettings`)
          .whenChanged()
          .pipe(
            filter(({ val }) => !!val),
            mergeMap(({ val }) =>
              state$.pipe(
                map((state) => getCalendarId(state, { userId, orgId })),
                filterIsTruthy(),
                map((calendarId) => dbClobberSettingsForCalendarId({ calendarId, settings: val! })),
                take(1),
              ),
            ),
          ),
        db
          .from(`orgs/${orgId as string}/userIds/${userId as string}/primaryCalendarId`)
          .whenChanged()
          .pipe(
            pluck('val'),
            map((val) =>
              val
                ? addCalendarIdForUserIdOrgIdPair({ calendarId: val, orgId, userId })
                : unsetCalendarIdForUserIdOrgIdPair({ orgId, userId }),
            ),
          ),
        db
          .from(`orgs/${orgId as string}/userIds/${userId as string}/scheduledBlocks`)
          .whenChild('added', 'changed', 'removed')
          .pipe(
            mergeMap(({ val, eventType }) =>
              state$.pipe(
                map((state) => getCalendarId(state, { userId, orgId })),
                filterIsTruthy(),
                map((calendarId) =>
                  dbClobberScheduledBlockForCalendarId({
                    block: val,
                    isRemoval: eventType === 'removed',
                    calendarId,
                  }),
                ),
                take(1),
              ),
            ),
          ),
      ).pipe(
        takeUntil(
          action$.pipe(
            ofAction(removeUserFromOrg),
            filter(({ payload: { userId: removedUserId } }) => removedUserId === userId),
          ),
        ),
      ),
    ),
  );

export const availabilityEpic: EpicWithDeps = (action$, state$) => {
  const setSelfStatusInSessionOverride = (status: UserStatus) =>
    of(getSelfUserStatus(state$.value)).pipe(
      // Ensure either our old status or the new status is `in-session` but not both
      filter((oldStatus) => (oldStatus === 'in-session' || status === 'in-session') && oldStatus !== status),
      // If Firebase says we're no longer in a session, but we know we are, ignore Firebase
      filter((oldStatus) => !(oldStatus === 'in-session' && getIsCurrentSessionNonTeamLobby(state$.value))),
      map(() => setSelfUserStatusOverride(status === 'in-session' ? 'in-session' : undefined)),
    );
  const readUserPresenceFromFirebase = (userId: UserId, selfUserId: UserId) =>
    db
      .from(`presenceHeartbeats/${userId as string}`)
      .whenChanged()
      .pipe(
        // filter(() => userId === selfUserId), // Uncomment this to ignore other userIds (useful for debugging)
        pluck('val'),
        filterIsTruthy(),
        // For selfUser, we want to ignore stale data, so ensure it's recent
        filter(({ presenceHeartbeatTs }) => userId !== selfUserId || wasRecentlyPresent(presenceHeartbeatTs)),
        map(({ presenceHeartbeatTs, status, statusReason }) => ({
          status: wasRecentlyPresent(presenceHeartbeatTs) ? status : ('away' as const),
          statusReason,
        })),
        switchMap(({ status, statusReason }) =>
          merge(
            userId !== selfUserId ? NEVER : setSelfStatusInSessionOverride(status),
            // For selfUser, never set status to `in-session`, since that's handled by the override
            (userId === selfUserId && (status === 'in-session' || status === 'away')) ||
              // For other users, never set status if it's unchanged
              (getUserStatusById(state$.value, userId) === status &&
                getUserStatusReasonById(state$.value, userId) === statusReason)
              ? NEVER
              : of(setUserStatus({ userId, status, statusReason })),
            userId === selfUserId
              ? NEVER
              : timer(5000).pipe(map(() => setUserStatus({ userId, status: 'away', statusReason }))),
          ),
        ),
      );

  const writeSelfUserPresenceToFirebase = (userId: UserId) =>
    state$.pipe(
      map((state) => getSelfUserStatus(state)),
      distinctUntilChanged(),
      map((status) => ({
        status,
        ...(status === 'available'
          ? { statusReason: undefined, selfUserStatusClearMs: undefined }
          : {
              statusReason: getSelfUserStatusReason(state$.value),
              selfUserStatusClearMs: getSelfUserStatusClearMs(state$.value),
            }),
      })),
      distinctUntilChangedDeep(),
      switchMap(({ status, statusReason }) =>
        timer(0, 3000).pipe(
          tap(
            () =>
              void db.from(`presenceHeartbeats/${userId as string}`).set({
                presenceHeartbeatTs: serverTimestamp(),
                status,
                ...(statusReason ? { statusReason } : {}),
              }),
          ),
        ),
      ),
      ignoreElements(),
    );

  return action$.pipe(
    ofActionPayload(setSelfUserId),
    switchMapIfTruthy((selfUserId) =>
      action$.pipe(
        ofActionPayload(addOrUpdateUser),
        groupBy(({ id }) => id),
        mergeMap((group$) =>
          group$.pipe(
            first(),
            mergeMap(({ id: userId }) =>
              merge(
                readUserPresenceFromFirebase(userId, selfUserId),
                userId !== selfUserId ? NEVER : writeSelfUserPresenceToFirebase(userId),
              ),
            ),
          ),
        ),
      ),
    ),
  );
};

export const refCountAddOrRemoveSession: EpicWithDeps = (action$, state$) => {
  const refCount$ = action$.pipe(ofActionPayload(listenToSession), refCountCreateDestroy());
  const inner$ = refCount$.pipe(
    filter(({ type }) => type === 'create'),
    mergeMap(({ payload: { sessionId, sessionInitiator } }) =>
      merge(
        of(
          addSession({
            id: sessionId,
            sessionInitiator,
            sessionGroupId: getSessionGroupIdForSessionInitiator(state$.value, sessionInitiator)!,
            name: getNameForSessionGroupIdAndSessionId(
              state$.value,
              getSessionGroupIdForSessionInitiator(state$.value, sessionInitiator)!,
              sessionId,
            ),
          }),
        ),
        merge(
          db
            .from(`sessions/${sessionId as string}/mediaServer`)
            .whenChanged()
            .pipe(pluck('val')),
          // "provider" is a proxy for this session existing in the database
          db
            .from(`sessions/${sessionId as string}/provider`)
            .whenChanged()
            .pipe(
              pluck('val'),
              filterIsTruthy(),
              map(() => ({ type: 'floof' as const })),
              take(1),
            ),
        ).pipe(
          mergeMap((mediaServer) =>
            merge(of(dbSetSessionExists(sessionId)), of(dbSetSessionMediaServer({ sessionId, mediaServer }))),
          ),
        ),
        db
          .from(`sessions/${sessionId as string}/isLocked`)
          .whenChanged()
          .pipe(
            pluck('val'),
            mapIsTruthy(),
            map((isLocked) => dbSetSessionIsLocked({ sessionId, isLocked })),
          ),
        db
          .from(`sessions/${sessionId as string}/peers`)
          .whenChanged()
          .pipe(
            map(({ val: peers }) =>
              lodashMap(peers, (peer, peerId) => {
                const sessionPeer: SessionPeer = {
                  peerId:
                    peerId === getFloofConnection(sessionId)?.getSelfPeerId()
                      ? 'self'
                      : (peerId as RemotePeerId),
                  userId: peer.userId,
                  sessionId,
                  isJoined: !!peer.isConnected,
                  displayName: peer.displayName ?? '',
                  avatarUrl: peer.avatarUrl ?? '',
                  pauseMessage: peer.pauseMessage,
                  pauseImage: peer.pauseImage,
                  isInactive: !!peer.isInactive,
                  lastInactivityUpdateTs: peer.lastInactivityUpdateTs,
                  isKnockMicEnabled: !!peer.isKnockMicEnabled,
                  knockStatus: peer.knockStatus,
                };
                return sessionPeer;
              }),
            ),
            distinctUntilChangedDeep(),
            map((peers) =>
              setSessionPeers({
                sessionId,
                peers,
              }),
            ),
          ),
        db
          .from(`sessions/${sessionId as string}/sessionHistoryId`)
          .whenChanged()
          .pipe(
            pluck('val'),
            filterIsTruthy(),
            waitUntilTruthy(() => state$.pipe(map((state) => getSessionById(state, sessionId)))),
            map((sessionHistoryId) =>
              dbSetCurrentSessionHistoryIdForSession({
                sessionId,
                sessionHistoryId: sessionHistoryId as SessionHistoryId,
              }),
            ),
          ),
        db
          .from(`sessions/${sessionId as string}/readonly/accounting/startTimeTs` as any)
          .whenChanged()
          .pipe(
            pluck('val'),
            filterIsTruthy(),
            map((startTs) => setStartTsForSession({ sessionId, startTs: startTs! })),
          ),
      ).pipe(
        takeUntil(
          refCount$.pipe(
            filter(
              ({ payload, type }) => type === 'destroy' && isEqual(payload, { sessionId, sessionInitiator }),
            ),
          ),
        ),
      ),
    ),
  );

  const removeSessionOnCompletion$ = refCount$.pipe(
    filter(({ type }) => type === 'destroy'),
    map(({ payload: { sessionId } }) => removeSession(sessionId)),
  );

  return merge(inner$, removeSessionOnCompletion$);
};

const listenToInstantMeeting = createAction<{
  payload: { orgId: OrgId; instantMeetingId: InstantMeetingId };
  type: 'create' | 'destroy';
}>('listenToInstantMeeting');
export const readInstantMeetingsFromDbEpic: EpicWithDeps = (action$, state$) => {
  const listenToCurrentInstantMeetingsForOrgUsers$ = state$.pipe(
    map((state) => getAllSelfAndSelfTeamsUserCurrentSessionInitiatorAndOrgIdPairs(state)),
    distinctUntilChangedDeep(),
    map((sessionInitiators) =>
      sessionInitiators.filter(({ sessionInitiator }) => isInstantMeetingSessionInitiator(sessionInitiator)),
    ),
    distinctUntilChangedDeep(),
    pairwiseDifference(),
    mergeMap(([added, deleted]) =>
      merge(
        ...added.map(({ orgId, sessionInitiator: { id } }) =>
          of(
            listenToInstantMeeting({
              payload: { instantMeetingId: id as InstantMeetingId, orgId },
              type: 'create',
            }),
          ),
        ),
        ...deleted.map(({ orgId, sessionInitiator: { id } }) =>
          of(
            listenToInstantMeeting({
              payload: { instantMeetingId: id as InstantMeetingId, orgId },
              type: 'destroy',
            }),
          ),
        ),
      ),
    ),
  );

  const refCount$ = action$.pipe(ofActionPayload(listenToInstantMeeting), refCountCreateDestroy());
  const listenToInstantMeeting$ = refCount$.pipe(
    filter(({ type }) => type === 'create'),
    mergeMap(({ payload: { instantMeetingId, orgId } }) =>
      db
        .from(`instantMeetings/${instantMeetingId as string}`)
        .query(endAt(orgId))
        .whenChanged()
        .pipe(
          pluck('val'),
          map((dbInstantMeeting) =>
            dbInstantMeetingCreatedOrUpdated({
              id: instantMeetingId,
              orgId,
              sessionGroupId: dbInstantMeeting.sessionGroupId,
              userIds: firebaseTruthyObjectToArray(dbInstantMeeting.userIds ?? {}) as UserId[],
              name: dbInstantMeeting.name,
            }),
          ),
          takeUntil(
            refCount$.pipe(
              filter(
                ({ payload, type }) => type === 'destroy' && isEqual(payload, { instantMeetingId, orgId }),
              ),
            ),
          ),
        ),
    ),
  );

  return merge(listenToCurrentInstantMeetingsForOrgUsers$, listenToInstantMeeting$);
};

export const readTeamUsersEventsFromDbEpic: EpicWithDeps = (action$, state$) => {
  const listenToTeamUsersEvents$ = state$.pipe(
    map((state) => getAllSelfAndSelfTeamsUserCurrentSessionInitiatorAndOrgIdPairs(state)),
    distinctUntilChangedDeep(),
    map((sessionInitiators) =>
      compact(
        sessionInitiators.map(({ sessionInitiator, orgId }) =>
          isEventInstanceSessionInitiator(sessionInitiator) ? { sessionInitiator, orgId } : undefined,
        ),
      ),
    ),
    distinctUntilChangedDeep(),
    pairwiseDifference(),
    mergeMap(([added, deleted]) =>
      merge(
        ...added.map(({ orgId, sessionInitiator }) =>
          of(
            listenToCalendarDay({
              payload: {
                calendarId: sessionInitiator.calendarId,
                orgId,
                dayTs: startOfDay(
                  getEventIdAndInstanceStartTsFromEventInstanceId(sessionInitiator.id).eventInstanceStartTs! -
                    getTimeZoneOffsetForSelf(state$.value),
                ).valueOf(),
              },
              type: 'create',
            }),
          ),
        ),
        ...deleted.map(({ orgId, sessionInitiator }) =>
          of(
            listenToCalendarDay({
              payload: {
                calendarId: sessionInitiator.calendarId,
                orgId,
                dayTs: startOfDay(
                  getEventIdAndInstanceStartTsFromEventInstanceId(sessionInitiator.id).eventInstanceStartTs! -
                    getTimeZoneOffsetForSelf(state$.value),
                ).valueOf(),
              },
              type: 'destroy',
            }),
          ),
        ),
      ),
    ),
  );
  return merge(listenToTeamUsersEvents$);
};

export const internalPersonalContactAddedOrRemoved: EpicWithDeps = (action$, state$) =>
  combineLatest([
    state$.pipe(
      map((state) => getPersonalOrgId(state)),
      distinctUntilChanged(),
    ),
    action$.pipe(ofActionPayload(setSelfUserId)),
  ]).pipe(
    switchMap(([personalOrgId, userId]) =>
      !personalOrgId || !userId
        ? of(setSelfPersonalContactLink(undefined))
        : merge(
            db
              .from(`users/${userId as string}/personal/personalContactLink` as $anyFixMe)
              .whenChanged()
              .pipe(
                pluck('val'),
                map((personalContactLink) => setSelfPersonalContactLink(personalContactLink)),
              ),
            db
              .from(`users/${userId as string}/personal/personalContacts`)
              .whenChild('added', 'removed')
              .pipe(
                mergeMap(({ key: personalContactUserId, eventType }) =>
                  eventType === 'removed'
                    ? of(
                        removeUserFromOrg({
                          userId: personalContactUserId as UserId,
                          orgId: personalOrgId,
                        }),
                      )
                    : db
                        .from(`users/${personalContactUserId}/public` as $anyFixMe)
                        .whenChanged()
                        .pipe(
                          pluck('val'),
                          filterIsTruthy(),
                          mergeMap(({ displayName, avatarUrl }: UserInfoPublic) =>
                            merge(
                              getUserById(state$.value, personalContactUserId as UserId)
                                ? NEVER
                                : of(
                                    addOrUpdateUser({
                                      displayName: displayName!,
                                      avatarUrl: avatarUrl!,
                                      id: personalContactUserId as UserId,
                                    }),
                                  ),
                              of(
                                addUserToOrg({
                                  userId: personalContactUserId as UserId,
                                  orgId: personalOrgId,
                                }),
                              ),
                            ),
                          ),
                        ),
                ),
              ),
          ),
    ),
  );

export const saveScheduledBlockChangesEpic: EpicWithDeps = (action$, state$) =>
  merge(
    action$.pipe(
      ofAction(addScheduledBlockToCalendar),
      map(({ payload: { calendarId, block } }) => ({ calendarId, blockId: block.id })),
    ),
    action$.pipe(
      ofAction(updateTimeForScheduledBlock),
      groupBy(({ payload: { calendarId, id } }) => `${calendarId}-${id}`),
      mergeMap((group$) =>
        group$.pipe(
          debounceTime(1000),
          map(({ payload: { calendarId, id } }) => ({ calendarId, blockId: id })),
        ),
      ),
    ),
    action$.pipe(
      ofAction(removeScheduledBlock),
      map(({ payload }) => payload),
    ),
  ).pipe(
    tag('firebase/saveScheduledBlockChangesEpic'),
    mergeMap(({ blockId }) =>
      db
        .from(
          `orgs/${getCurrentUrlParams()?.orgId as string}/userIds/${
            getSelfUserId(state$.value) as string
          }/scheduledBlocks/${blockId}`,
        )
        .set(omitBy(getScheduledBlockById(state$.value, blockId), isUndefined) as DbCalendarScheduledBlock),
    ),
    ignoreElements(),
  );

export const setIsSelfInactiveEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(setIsSelfInactive),
    tap(({ payload: isSelfInactive, meta }) => {
      const sessionId = meta?.sessionId ?? getCurrentSessionId(state$.value);
      debugCheck(!!sessionId, 'Session ID did not exist for action setIsSelfInactive epic');
      const floofConnection = getFloofConnection(sessionId!);
      if (!floofConnection) return;
      const peerId = floofConnection.getSelfPeerId();
      if (!peerId) return;
      void db
        .from(`sessions/${sessionId as string}/peers/${peerId as string}`)
        .update(['isInactive' as const, isSelfInactive], [
          'lastInactivityUpdateTs' as const,
          serverTimestamp(),
        ] as $anyFixMe);
    }),
    ignoreElements({ silenceDevEpicWarning: true }),
  );

export const setIsSelfPausedEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofAction(setIsSelfPaused),
    tap(({ payload: pauseMessage, meta: { sessionId } }) => {
      debugCheck(!!sessionId, 'Session ID did not exist for action setIsSelfPaused epic');
      const peerId = getFloofConnection(sessionId!).getSelfPeerId();
      if (!peerId) return;
      const ref = db.from(
        `sessions/${sessionId as string}/peers/${peerId as string}/pauseMessage`,
      ) as RxFirebaseDbWrapper<DbPeer['pauseMessage']>;

      if (pauseMessage) {
        void ref.set(pauseMessage);
      } else {
        void ref.remove();
      }
    }),
    ignoreElements({ silenceDevEpicWarning: true }),
  );

// export const setIsKnockMicEnabledEpic: EpicWithDeps = (action$, state$) =>
//   merge(
//     action$.pipe(ofActionPayload(setIsKnockMicEnabled)),
//     action$.pipe(ofActionPayload(toggleIsKnockMicEnabled)),
//   ).pipe(
//     map(() => getIsKnockMicEnabled(state$.value)),
//     tap((isKnockMicEnabled) => {
//       const sessionId = getCurrentSessionId(state$.value);
//       if (!sessionId) return;
//       const peerId = getSelfPeerIdForSessionId(state$.value, sessionId);
//       if (!peerId) return;
//       void (
//         db.from(`sessions/${sessionId as string}/peers/${peerId as string}/live`) as RxFirebaseDbWrapper<
//           PeerData['live']
//         >
//       )
//         .from('isKnockMicEnabled')
//         .set(isKnockMicEnabled ?? null);
//     }),
//     ignoreElements(),
//   );

// export const setIsKnockingEpic: EpicWithDeps = (action$, state$) =>
//   action$.pipe(
//     ofActionPayload(setIsKnocking),
//     map((isKnocking) => {
//       const sessionId = getCurrentSessionId(state$.value);
//       if (!sessionId) return;
//       const peerId = getSelfPeerIdForSessionId(state$.value, sessionId);
//       if (!peerId) return;
//       void (
//         db.from(`sessions/${sessionId as string}/peers/${peerId as string}/live`) as RxFirebaseDbWrapper<
//           PeerData['live']
//         >
//       )
//         .from('knockStatus')
//         .set(isKnocking ? 'knocking' : null);
//     }),
//     ignoreElements(),
//   );

// export const setIsSessionLockedEpic: EpicWithDeps = (action$, state$) =>
//   action$.pipe(
//     ofActionPayload(setIsSessionLocked),
//     map(({ sessionId, isLocked }) => db.from(`sessions/${sessionId as string}/isLocked`).set(isLocked)),
//     ignoreElements(),
//   );

export const userRangUserInSessionEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(userRangUser),
    tap(({ payload: { sessionId, orgId, receiverUserId, senderUserId, joinableSessionInitiator } }) =>
      (
        db.from(
          `messageBus/${db.from('messageBus').getPushKeyWithPrefix('msg') as string}`,
        ) as RxFirebaseDbWrapper<DbRingMessage>
      ).set({
        type: 'ring',
        senderUserId,
        receiverUserId,
        ...(orgId !== getPersonalOrgId(state$.value) && { orgId }),
        sessionId,
        joinableSessionInitiator,
        state: 'ringing',
      }),
    ),
    ignoreElements(),
  );

export const handleNewMessageEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(selfAddedMessageId),
    mergeMap(({ payload: messageId }) =>
      db
        .from(`messageBus/${messageId as string}`)
        .whenChanged()
        .pipe(
          switchMap(({ val: dbMessage }) =>
            !dbMessage
              ? of(dbDeleteRing(messageId))
              : isDbMessageRing(dbMessage)
              ? of(
                  dbAddOrUpdateRing({
                    orgId: getPersonalOrgId(state$.value)!,
                    ...(dbMessage as DbRingMessage),
                    id: messageId,
                  }),
                )
              : NEVER,
          ),
        ),
    ),
  );

export const handleRingResponseEpic: EpicWithDeps = (action$, state$) =>
  merge(
    action$.pipe(
      ofAction(userAcceptedRing),
      map(({ payload: ringId }) => ({ ringId, didAccept: true })),
    ),
    action$.pipe(
      ofAction(userDeclinedRing),
      map(({ payload: ringId }) => ({ ringId, didAccept: false })),
    ),
  ).pipe(
    tap(({ ringId, didAccept }) =>
      (db.from(`messageBus/${ringId as string}`) as unknown as RxFirebaseDbWrapper<DbRingMessage>)
        .from('state')
        .set(didAccept ? 'accepted' : 'declined'),
    ),
    ignoreElements(),
  );

export const writeTranscriptToFirebaseEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(utteranceTranscribed),
    groupBy(({ tempUniqueId }) => tempUniqueId),
    mergeMap((group$) =>
      group$.pipe(
        withLatestFrom(
          group$.pipe(
            first(),
            map(({ sessionId }) => ({
              sessionHistoryId: getCurrentSessionHistoryIdForSession(state$.value, sessionId),
            })),
            map(({ sessionHistoryId }) => ({
              sessionHistoryId,
              sessionHistoryEntryId: db
                .from(`sessionHistory/${sessionHistoryId as string}/entries`)
                .getPushKeyWithPrefix('she'),
            })),
          ),
        ),
        tap(([{ isFinal, words, ts, peer }, { sessionHistoryId, sessionHistoryEntryId }]) =>
          db
            .from(`sessionHistory/${sessionHistoryId as string}/entries/${sessionHistoryEntryId as string}`)
            .set({
              ...peer,
              ts,
              details: {
                type: 'transcription',
                words,
                isFinal,
                transcriptionType: 'speech',
              },
            }),
        ),
        // eslint-disable-next-line rxjs/no-ignored-takewhile-value
        takeWhile(([{ isFinal }]) => !isFinal),
      ),
    ),
    ignoreElements(),
  );

export const writeWaveToFirebaseEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(userWaved),
    map(() => getCurrentSessionId(state$.value)!),
    map((sessionId) => ({
      sessionHistoryId: getCurrentSessionHistoryIdForSession(state$.value, sessionId),
      peerId: getFloofConnection(sessionId).getSelfPeerId(),
      userId: getSelfUserId(state$.value)!,
    })),
    map(({ userId, ...etc }) => ({
      userId,
      ...etc,
      avatarUrl: getUserAvatarUrlById(state$.value, userId),
    })),
    tap(({ sessionHistoryId, peerId, userId, avatarUrl }) =>
      db
        .from(
          `sessionHistory/${sessionHistoryId as string}/entries/${
            db
              .from(`sessionHistory/${sessionHistoryId as string}/entries`)
              .getPushKeyWithPrefix('she') as string
          }`,
        )
        .set({
          peerId,
          userId,
          ...(avatarUrl && { avatarUrl }),
          displayName: getUserDisplayNameById(state$.value, userId) ?? '',
          ts: serverTimestamp(),
          details: {
            type: 'transcription',
            words: 'wave',
            isFinal: true,
            transcriptionType: 'emoji',
          },
        }),
    ),
    ignoreElements(),
  );

export const refCountListenToCalendarDayEpic: EpicWithDeps = (action$, state$) => {
  const refCount$ = action$.pipe(ofActionPayload(listenToCalendarDay), refCountCreateDestroy());

  const listenToSessionHistoryOnDay$ = refCount$.pipe(
    filter(({ type }) => type === 'create'),
    mergeMap(({ payload }) => {
      const { orgId, dayTs } = payload;
      const day = new Date(dayTs);
      const inner$ = db
        .from(
          `users/${getSelfUserId(state$.value) as string}/readonly/orgIds/${
            orgId as string
          }/sessionHistoryByDate/${day.getUTCFullYear()}/${day.getUTCMonth()}/${day.getUTCDate()}`,
        )
        // Since the value is only ever set to `true`, we don't have to listen to 'changed'
        .whenChild('added', 'removed')
        .pipe(
          map(({ key, eventType }) => ({ sessionHistoryId: key as SessionHistoryId, eventType })),
          map(({ eventType, sessionHistoryId }) =>
            listenToSessionHistory({
              payload: {
                sessionHistoryId,
                orgId,
              },
              type: eventType === 'added' ? 'create' : 'destroy',
            }),
          ),
          takeUntil(
            refCount$.pipe(
              filter(
                ({ type, payload: destroyPayload }) => type === 'destroy' && isEqual(destroyPayload, payload),
              ),
            ),
          ),
        );

      // If the last emitted listenToSessionHistory action was of type 'create',
      // destroy it
      const destroySessionHistoryListenerOnCompletion$ = forkJoin([inner$]).pipe(
        filter(([{ payload }]) => payload.type === 'create'),
        map(
          ([
            {
              payload: { payload },
            },
          ]) => listenToSessionHistory({ payload, type: 'destroy' }),
        ),
      );

      return merge(inner$, destroySessionHistoryListenerOnCompletion$);
    }),
  );

  const listenToEventsOnDay$ = refCount$.pipe(
    filter(({ type }) => type === 'create'),
    mergeMap(({ payload }) => {
      const { calendarId, dayTs, orgId } = payload;
      const day = new Date(dayTs);
      const inner$ = db
        .from(
          `calendars/${
            calendarId as string
          }/events/${day.getUTCFullYear()}/${day.getUTCMonth()}/${day.getUTCDate()}`,
        )
        .whenChild('added', 'changed', 'removed')
        .pipe(
          startWith(
            undefined as any as {
              key: string;
              val: DbEventInstance;
              eventType: 'added' | 'changed' | 'removed';
            },
          ),
          pairwise(),
          mergeMap(
            ([
              prev,
              {
                eventType,
                key,
                val: { endTs },
              },
            ]) =>
              merge(
                of(
                  listenToEvent({
                    payload: {
                      calendarId,
                      orgId,
                      eventInstanceId: key as EventInstanceId,
                      endTs,
                    },
                    type: eventType === 'added' || eventType === 'changed' ? 'create' : 'destroy',
                  }),
                ),
                eventType === 'changed' && prev
                  ? of(
                      listenToEvent({
                        payload: {
                          calendarId,
                          orgId,
                          eventInstanceId: prev.key as EventInstanceId,
                          endTs: prev.val.endTs,
                        },
                        type: 'destroy',
                      }),
                    )
                  : NEVER,
              ),
          ),
          takeUntil(
            refCount$.pipe(
              filter(
                ({ payload: destroyPayload, type }) => type === 'destroy' && isEqual(destroyPayload, payload),
              ),
            ),
          ),
        );

      // If the last emitted listenToEvent action was of type 'create', destroy
      // it
      const destroyEventListenerOnCompletion$ = forkJoin([inner$]).pipe(
        filter(([{ payload }]) => payload.type === 'create'),
        map(
          ([
            {
              payload: { payload },
            },
          ]) => listenToEvent({ payload, type: 'destroy' }),
        ),
      );
      return merge(inner$, destroyEventListenerOnCompletion$);
    }),
  );

  return state$.pipe(
    map((state) => getFeatureFlag(state, 'calendar')),
    distinctUntilChanged(),
    switchMapIfTruthy(() => merge(listenToSessionHistoryOnDay$, listenToEventsOnDay$)),
  );
};

export const refCountListenToSessionHistoryEpic: EpicWithDeps = (action$, state$) => {
  const refCount$ = action$.pipe(ofActionPayload(listenToSessionHistory), refCountCreateDestroy());

  return refCount$.pipe(
    filter(({ type }) => type === 'create'),
    mergeMap(({ payload: { sessionHistoryId, orgId } }) =>
      combineLatest([
        db
          .from(`sessionHistory/${sessionHistoryId as string}/sessionInitiator`)
          .whenChanged()
          .pipe(pluck('val')),
        db
          .from(`sessionHistory/${sessionHistoryId as string}/sessionId`)
          .whenChanged()
          .pipe(pluck('val')),
        db
          .from(`sessionHistory/${sessionHistoryId as string}/startTs`)
          .whenChanged()
          .pipe(pluck('val')),
        db
          .from(`sessionHistory/${sessionHistoryId as string}/name`)
          .whenChanged()
          .pipe(pluck('val')),
      ]).pipe(
        mergeMap(([sessionInitiator, sessionId, startTs, name]) =>
          merge(
            of(
              dbSessionHistoryCreated({
                orgId,
                sessionHistory: {
                  id: sessionHistoryId,
                  sessionId,
                  sessionInitiator,
                  startTs,
                  name,
                },
              }),
            ),
            db
              .from(`sessionHistory/${sessionHistoryId as string}/entries`)
              .query(
                endAt(orgId),
                ...(sessionInitiator?.type === 'team'
                  ? [orderByChild('ts'), startAfter(subDays(Date.now(), 1).valueOf(), 'ts')]
                  : []),
              )
              .whenChild('added', 'changed', 'removed')
              .pipe(
                map(({ eventType, key, val: { displayName, peerId, ts, details, avatarUrl, userId } }) =>
                  eventType === 'added' || eventType === 'changed'
                    ? dbSessionHistoryEntryCreatedOrUpdated({
                        id: key as SessionHistoryEntryId,
                        sessionHistoryId,
                        ts: ts,
                        details,
                        peer: {
                          displayName: displayName ?? '',
                          peerId: peerId as AnyPeerId,
                          avatarUrl,
                          userId,
                        },
                      })
                    : eventType === 'removed'
                    ? dbSessionHistoryEntryDeleted({
                        id: key as SessionHistoryEntryId,
                        sessionHistoryId,
                      })
                    : clientAssertExhaustedType(eventType),
                ),
              ),
          ),
        ),
        takeUntil(
          refCount$.pipe(
            filter(
              ({ type, payload: { sessionHistoryId: destroySessionHistoryId } }) =>
                type === 'destroy' && sessionHistoryId === destroySessionHistoryId,
            ),
          ),
        ),
      ),
    ),
  );
};

export const joinPotentiallyUnknownSessionByIdEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(joinPotentiallyUnknownSessionById.start),
    // With an exhaustMap, if for some reason this meeting isn't found in the
    // store, we will never be able to join another meeting until app relaunch.
    mergeMap(({ requestId, sessionId }) =>
      apiResponseAsObservable(apiCall('wrapOldRoomWithNewInstantMeeting', { sessionId })).pipe(
        pluck('instantMeetingId'),
        filterIsTruthy(),
        map(
          (instantMeetingId) =>
            ({
              type: 'instant-meeting',
              id: instantMeetingId!,
            } as InstantMeetingSessionInitiator),
        ),
        mergeMap((joinableSessionInitiator) =>
          merge(
            of(
              joinPotentiallyUnknownSessionById.complete({
                request: { requestId, sessionId },
                response: undefined,
              }),
            ),
            state$.pipe(
              map((state) => getHeadSessionIdForSessionInitiator(state, joinableSessionInitiator)!),
              filterIsTruthy(),
              map((sessionId) =>
                joinKnownSession({
                  sessionId,
                  joinableSessionInitiator,
                }),
              ),
              take(1),
            ),
          ),
        ),
        catchError((error: unknown) =>
          of(
            joinPotentiallyUnknownSessionById.error({
              request: { requestId, sessionId },
              error,
            }),
          ),
        ),
      ),
    ),
  );

export const refCountListenToEventInstanceEpic: EpicWithDeps = (action$, state$) => {
  const refCount$ = action$.pipe(ofActionPayload(listenToEventInstance), refCountCreateDestroy());
  const listener$ = refCount$.pipe(
    filter(({ type }) => type === 'create'),
    mergeMap(({ payload: { calendarId, eventInstanceId, orgId } }) => {
      const startTs = getEventIdAndInstanceStartTsFromEventInstanceId(eventInstanceId)?.eventInstanceStartTs;
      if (!startTs) return NEVER;
      const day = new Date(startOfDay(startTs! - getTimeZoneOffsetForSelf(state$.value)).valueOf());
      const inner$ = db
        .from(
          `calendars/${
            calendarId as string
          }/events/${day.getUTCFullYear()}/${day.getUTCMonth()}/${day.getUTCDate()}/${
            eventInstanceId as string
          }/endTs`,
        )
        .whenChanged()
        .pipe(
          startWith(undefined as any),
          pairwise(),
          mergeMap(([prev, { eventType, val: endTs }]) =>
            merge(
              of(
                listenToEvent({
                  payload: { calendarId, eventInstanceId, endTs, orgId },
                  type: 'create',
                }),
              ),
              eventType === 'changed' && prev
                ? of(
                    listenToEvent({
                      payload: {
                        calendarId,
                        orgId,
                        eventInstanceId: prev.key as EventInstanceId,
                        endTs: prev.val,
                      },
                      type: 'destroy',
                    }),
                  )
                : NEVER,
            ),
          ),
          takeUntil(
            refCount$.pipe(
              filter(
                ({ type, payload: destroyPayload }) =>
                  type === 'destroy' && isEqual(destroyPayload, { calendarId, eventInstanceId, orgId }),
              ),
            ),
          ),
        );

      // If the last emitted listenToEvent action was of type 'create', destroy
      // it
      const destroyOnCompletion$ = forkJoin([inner$]).pipe(
        map(
          ([
            {
              payload: { payload },
            },
          ]) => listenToEvent({ payload, type: 'destroy' }),
        ),
      );

      return merge(inner$, destroyOnCompletion$);
    }),
  );

  return state$.pipe(
    map((state) => getFeatureFlag(state, 'calendar')),
    distinctUntilChanged(),
    switchMapIfTruthy(() => listener$),
  );
};

export const refCountListenToEventEpic: EpicWithDeps = (action$, state$) => {
  const refCount$ = action$.pipe(ofActionPayload(listenToEvent), refCountCreateDestroy());
  const listener$ = refCount$.pipe(
    filter(({ type }) => type === 'create'),
    map(({ payload: { calendarId, eventInstanceId, orgId, endTs } }) => ({
      calendarId,
      eventInstanceId,
      orgId,
      endTs,
      ...getEventIdAndInstanceStartTsFromEventInstanceId(eventInstanceId),
    })),
    mergeMap(({ calendarId, eventInstanceId, orgId, endTs, eventId, eventInstanceStartTs: startTs }) => {
      const inner$ = db
        .from(`events/${eventId as string}`)
        .query(endAt(orgId))
        .whenChanged()
        .pipe(
          catchError((error: unknown) => {
            console.error(error);
            return NEVER;
          }),
          map(({ val: { status, ...etc } }) => (status === 'cancelled' ? undefined : etc)),
          startWith(undefined as DbEvent | undefined),
          pairwise(),
          filter(([prev, curr]) => !isEqual(prev, curr)),
          mergeMap(([prev, curr]) =>
            merge(
              !curr
                ? NEVER
                : of(undefined).pipe(
                    map(() =>
                      dbEventCreatedOrUpdated({
                        calendarId,
                        event: {
                          id: eventInstanceId,
                          orgId,
                          eventId: eventId!,
                          startTs: startTs!,
                          endTs,
                          name: curr.name,
                          sessionGroupId: curr.sessionGroupId,
                        },
                      }),
                    ),
                  ),
              !prev
                ? NEVER
                : of(undefined).pipe(
                    map(() =>
                      dbEventDeleted({
                        calendarId,
                        eventInstanceId,
                      }),
                    ),
                  ),
            ),
          ),
          takeUntil(
            refCount$.pipe(
              filter(
                ({ type, payload: destroyPayload }) =>
                  type === 'destroy' &&
                  isEqual(destroyPayload, { calendarId, eventInstanceId, orgId, endTs }),
              ),
            ),
          ),
        );

      // If the last emitted action was dbEventCreatedOrUpdated (i.e. it had an
      // event in it), delete it
      const destroyOnCompletion$ = forkJoin([inner$]).pipe(
        filter(([{ payload }]) => (payload as any).event),
        map(([{ payload }]) =>
          dbEventDeleted({
            calendarId: payload.calendarId,
            eventInstanceId: (payload as any).event.id,
          }),
        ),
      );

      return merge(inner$, destroyOnCompletion$);
    }),
  );
  return state$.pipe(
    map((state) => getFeatureFlag(state, 'calendar')),
    switchMapIfTruthy(() => listener$),
  );
};

export const writeCalendarSettingsToFirebaseEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(setCalendarSettingsForCalendarId),
    tap((payload) => {
      const { calendarId, ...etc } = payload as $anyFixMe;
      const userOrgIds = getUserAndOrgIdsFromCalendarIdSlow(state$.value, calendarId);
      if (!userOrgIds) return console.error('Could not find calendar id for settings update');
      const { userId, orgId } = userOrgIds;

      void (db.from(`orgs/${orgId as string}/userIds/${userId as string}/calendarSettings`).update as any)(
        // Map undefined's to null for firebase to delete them.
        ...lodashMap(etc, (value, key) => [key, isUndefined(value) ? null : value]),
      );
    }),
    ignoreElements(),
  );

export const localhostDevSetUserDbIfAbsentEpic: EpicWithDeps = (action$, state$) => {
  if (isStorybook) return NEVER;
  if (!isLocalhost) return NEVER;
  const auth = getAuth(getFirebaseApp());
  return new Observable<User | null>((obs) => onAuthStateChanged(auth, obs)).pipe(
    map((authUser) => ({
      userId: authUser?.uid as UserId,
      email: authUser?.email as string,
      displayName: authUser?.displayName,
    })),
    switchMap(({ userId, email, displayName }) =>
      !userId
        ? NEVER
        : from(db.from(`users/${userId as string}`).exists()).pipe(
            filterIsFalsey(),
            tap(
              () =>
                void db.from(`users/${userId as string}/public`).set({
                  displayName: displayName ?? 'Anonymous',
                  email,
                } as any),
            ),
          ),
    ),
    ignoreElements(),
  );
};
