import Bowser from 'bowser';
import { gte } from 'semver';

import { DesktopAppPlatform, PopPlatform } from 'common/models/platform.interface';
import { debugCheck } from 'utils/debug-check';

import { sendIpc } from '../../pages/vo/vo-react/ipc/send-ipc';

const { os, browser } = Bowser.parse(navigator.userAgent);

export const isElectron = browser.name === 'Electron';

export const isMac = os.name === 'macOS';
export const isWindows = os.name === 'Windows';
export const isLinux = os.name === 'Linux';
export const isAndroid = os.name === 'Android';
export const isIos = os.name === 'iOS';
export const isChromeOs = os.name === 'Chrome OS';

export const getPopPlatform = (): PopPlatform => {
  if (!isElectron) return 'client';
  if (isMac) return 'mac';
  if (isWindows) return 'windows';
  if (isLinux) return 'linux';
  debugCheck('Got unknown platform, defaulting to client');
  return 'client';
};

export const getDesktopAppPlatform = (): DesktopAppPlatform => {
  if (isMac) return 'mac';
  if (isWindows) return 'windows';
  if (isLinux) return 'linux';
  debugCheck('Got unknown desktop platform, defaulting to mac');
  return 'mac';
};

export const getPopOs = () => {
  if (isMac) return 'mac';
  if (isWindows) return 'windows';
  return 'linux';
};

export const currentPopPlatform = getPopPlatform();

export const osVersion = os.version ?? '';

export const desktopOsInfo = isElectron ? sendIpc('getPlatformInfo') : Promise.resolve(undefined);

// Only supported in Windows 11 22H2 and up: https://www.electronjs.org/docs/latest/api/browser-window#winsetbackgroundmaterialmaterial-windows
export const doesSupportBackgroundMaterial = new Promise((resolve) => {
  if (isElectron || currentPopPlatform !== 'windows') return resolve(false);
  return desktopOsInfo.then((info) => {
    try {
      return resolve(gte(info!.osVersion, '10.0.22621'));
    } catch {
      return resolve(false);
    }
  });
});
