import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  groupBy,
  ignoreElements,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';

import { RemotePeerId } from 'common/models/db/vo.interface';
import {
  mapSelector,
  ofActionPayload,
  switchMapExists,
  switchMapIfTruthy,
} from 'common/utils/custom-rx-operators';
import { observeUnsubscribe } from 'common/utils/observe-unsubscribe';
import { removeLogicalTrack, trackReceived } from 'pages/vo/vo-react/features/floof/floof.slice';
import { ingestProvisionalTrack, ingestTrack } from 'pages/vo/vo-react/features/media/media.slice';
import { isBrowser } from 'utils/client-utils';
import { getFloofConnection } from 'utils/floof-sdk/floof-sdk';
import { getEnhancedTrackForTrack } from 'utils/react/audio-context';
import { getRefCountedTrack, makeAudioTrack } from 'utils/react/get-user-media';
import { getDesktopAppPlatform } from 'utils/react/user-agent';

import { Actionish, EpicWithDeps } from '../../redux/app-store';
import { getIsCurrentSessionWalkieTalkie } from '../common/session-selectors';
import { getIsCameraEnabled, getIsMicrophoneEnabled } from '../devices/devices.slice';
import { observePreferredInputDeviceIdOfType } from '../devices/devices.utils';
import { mergeMapDuringSession } from '../floof/floof.utils';
import { getScreenInfoForId, getSharedScreenIdAndRect, stopSharingScreen } from '../screens/screens.slice';

import {
  deregisterTrackWithVideoElementCache,
  registerTrackWithVideoElementCache,
} from './video-element-cache';

export const tracksReceivedFromFloofEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(trackReceived),
    map(({ peerId, track: trackInfo, sessionId }) =>
      ('track' in trackInfo && trackInfo.track ? ingestTrack : ingestProvisionalTrack)({
        peerId: peerId as RemotePeerId,
        trackInfo: {
          ...trackInfo,
          type: trackInfo.mediaType === 'mic' ? 'microphone' : trackInfo.mediaType,
        } as any,
        sessionId,
      }),
    ),
  );

export const manageMicrophoneTrackForSessionEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      // Start the microphone management when we're in a session and we're
      //  a) unmuted in a walkie talkie session
      //  b) in a full call session, regardless of mute state (mute/unmute is managed further down in this case)
      combineLatest([
        state$.pipe(
          mapSelector((state) => !getIsCurrentSessionWalkieTalkie(state) || getIsMicrophoneEnabled(state)),
        ),
        observePreferredInputDeviceIdOfType('microphone'),
      ]).pipe(
        map(([shouldGrabTrack, deviceId]) => {
          if (shouldGrabTrack && !deviceId) {
            const trackPromise = makeAudioTrack({ deviceId: 'default' });
            void trackPromise.then((track) => getEnhancedTrackForTrack(track)?.destroy());
          }

          return shouldGrabTrack ? deviceId : undefined;
        }),
        distinctUntilChanged(),
        switchMapIfTruthy((deviceId) => {
          // When we do have a deviceId, grab the track and hang on to it until
          // either this whole pipe unsubscribes (because we've left the
          // session), or deviceId becomes undefined, or this switchMap is
          // triggered again with a new deviceId.
          const { track: trackPromise, release } = getRefCountedTrack({ type: 'microphone', deviceId });
          const logicalTrackId = getFloofConnection(sessionId).setSelfMediaTrack({
            mediaType: 'mic',
            track: trackPromise,
            deviceId: deviceId,
            isEnabled: getIsMicrophoneEnabled(state$.value),
          });

          return merge(
            state$.pipe(
              mapSelector(getIsMicrophoneEnabled),
              withLatestFrom(trackPromise),
              tap(([isMicrophoneEnabled, track]) => {
                // We need to do two things here because floof SDK is a bit
                // delinquent right now.
                // - First we tell floofSdk to notify everyone else that this
                //   track is (un)muted.
                // - Then we go ahead and (un)mute the track itself.
                //
                // This is because Floof SDK is in a sort of in between state
                // right now, where all of the notification pieces are expecting
                // consumers to call removeSelfMediaTrack when they don't want
                // media flowing, but the media sender side is expecting long
                // living tracks with mute state managed outside of itself. This
                // is unique to the microphone track because other tracks we can
                // just stop the source and restart it with a new track to
                // pacify the media stack.

                getFloofConnection(sessionId).setSelfMediaTrackEnabled(logicalTrackId, isMicrophoneEnabled);

                track.enabled = isMicrophoneEnabled;
              }),
              ignoreElements(),
            ),
            observeUnsubscribe(async () => {
              getFloofConnection(sessionId)?.removeSelfMediaTrack(logicalTrackId);
              release();
            }),
          );
        }),
      ),
    ),
  );

export const manageCameraForSessionEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      combineLatest([
        state$.pipe(
          map((state) => getIsCameraEnabled(state)),
          distinctUntilChanged(),
        ),
        observePreferredInputDeviceIdOfType('camera'),
      ]).pipe(
        map(([isCameraEnabled, deviceId]) =>
          isCameraEnabled && deviceId ? { type: 'camera' as const, deviceId } : undefined,
        ),
        switchMapExists(({ deviceId }) => {
          const { track, release } = getRefCountedTrack({ type: 'camera', deviceId });
          const logicalTrackId = getFloofConnection(sessionId).setSelfMediaTrack({
            mediaType: 'camera',
            track,
            deviceId,
            isEnabled: true,
          });

          return observeUnsubscribe(async () => {
            release();
            getFloofConnection(sessionId)?.removeSelfMediaTrack(logicalTrackId);
          });
        }),
      ),
    ),
  );

export const manageScreenShareForSessionEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      state$.pipe(
        map((state) => getSharedScreenIdAndRect(state)),
        distinctUntilChanged(),
        map((screenInfo) => {
          if (!screenInfo) return undefined;
          if (isBrowser) {
            return {
              id: screenInfo.id,
              type: 'screen' as const,
            };
          }

          const {
            sourceId,
            bounds: screenBounds,
            scaleFactor,
          } = getScreenInfoForId(state$.value, screenInfo.id)!;

          return {
            id: screenInfo.id,
            type: 'screen' as const,
            sourceId,
            captureRect: screenInfo.rect,
            scaleFactor,
            platform: getDesktopAppPlatform(),
            screenBounds,
          };
        }),
        switchMapExists((info): Observable<Actionish> => {
          const { track, release } = getRefCountedTrack(info);
          const logicalTrackId = getFloofConnection(sessionId).setSelfMediaTrack({
            mediaType: 'screen',
            track,
            deviceId: info.id,
            scaleFactor: info.scaleFactor,
            platform: info.platform,
            isEnabled: true,
          });

          return merge(
            from(track).pipe(
              catchError(() => of(stopSharingScreen())),
              ignoreElements(),
            ),
            observeUnsubscribe(async () => {
              release();
              getFloofConnection(sessionId)?.removeSelfMediaTrack(logicalTrackId);
            }),
          );
        }),
      ),
    ),
  );

export const createCachedVideoElementEpic: EpicWithDeps = (action$) =>
  merge(
    action$.pipe(
      ofActionPayload(ingestTrack),
      filter(({ trackInfo: { type } }) => type === 'camera' || type === 'screen'),
      map(({ trackInfo: { logicalTrackId, track } }) => ({ logicalTrackId, track })),
      groupBy(({ logicalTrackId }) => logicalTrackId),
      mergeMap((ingestedByLogicalTrackId$) =>
        ingestedByLogicalTrackId$.pipe(
          distinctUntilChanged((prev, current) => prev.track === current.track),
          tap(({ logicalTrackId, track }) => {
            if (!track) {
              return deregisterTrackWithVideoElementCache(logicalTrackId);
            }
            registerTrackWithVideoElementCache(logicalTrackId, track);
          }),
          ignoreElements(),
        ),
      ),
    ),
    action$.pipe(
      ofActionPayload(removeLogicalTrack),
      tap(({ logicalTrackId }) => {
        deregisterTrackWithVideoElementCache(logicalTrackId);
      }),
      ignoreElements(),
    ),
  );
