import { DistributiveOmit } from 'common/utils/ts-utils';

import { AnyPeerId } from '../models/peer.interface';

import { Point } from './geometry.interface';

export interface BaseLocalIagoEvent {
  blockState?: 'exclusive-control' | 'cosumer-specified-block';
  peerId: AnyPeerId;
  role: 'local' | 'remote';
}

// Include KeyboardEvent even though we might not actually send it to guests —
// decide in JS whether we want to send privacy-conscious keyboard updates.
export type LocalIagoEvent =
  | LocalizeIagoEvent<KeyboardEvent | PointerEvent | ControlChangeEvent>
  | CursorChangeEvent;
export type RemoteIagoEvent = KeyboardEvent | PointerEvent | CursorChangeEvent | ControlChangeEvent;

type IncompleteKeyboardEvent = Omit<KeyboardEvent, 'location'>;
export type IncompleteRemoteIagoEvent = IncompleteKeyboardEvent | PointerEvent;

export type LocalizeIagoEvent<T> = DistributiveOmit<T, 'trackId'> & BaseLocalIagoEvent;

export type SingleCursorDataRepresentation = {
  data: number[];
  hotspot: Point;
};

export type CursorChangeEvent = {
  type: 'cursor-change';
} & CursorDataRepresentation;

export interface CursorDataRepresentation {
  cursorType: 'single-dpi-aware-representation' | 'multiple-representations';
  singleDpiAwareRepresentation?: SingleCursorDataRepresentation;
  multipleRepresentations?: SingleCursorDataRepresentation[];
}

export interface CursorDataRepresentationSingleDpiAware extends CursorDataRepresentation {
  cursorType: 'single-dpi-aware-representation';
  singleDpiAwareRepresentation: SingleCursorDataRepresentation;
}

export interface CursorRepresentationMultiple extends CursorDataRepresentation {
  cursorType: 'multiple-representations';
  multipleRepresentations: SingleCursorDataRepresentation[];
}

export function isCursorDataRepresentationSingleDpiAware(
  cursorRepresentation: CursorDataRepresentation,
): cursorRepresentation is CursorDataRepresentationSingleDpiAware {
  return cursorRepresentation.cursorType === 'single-dpi-aware-representation';
}

export function isCursorDataRepresentationMultiple(
  cursorRepresentation: CursorDataRepresentation,
): cursorRepresentation is CursorRepresentationMultiple {
  return cursorRepresentation.cursorType === 'multiple-representations';
}

export interface ControlChangeEvent extends BaseLocalIagoEvent {
  type: 'control-change';
}

export function isKeyboardEvent<T extends { type: 'keyboard' }>(event: { type: string }): event is T {
  return event.type === 'keyboard';
}

export function isPointerEvent<T extends { type: 'click' | 'move' | 'drag' | 'scroll' }>(event: {
  type: string;
}): event is T {
  return event.type === 'click' || event.type === 'move' || event.type === 'drag' || event.type === 'scroll';
}

export function isControlChangeEvent<T extends { type: 'control-change' }>(event: {
  type: string;
}): event is T {
  return event.type === 'control-change';
}

export function isCursorChangeEvent<T extends { type: 'cursor-change' }>(event: {
  type: string;
}): event is T {
  return event.type === 'cursor-change';
}

interface TrackEvent {
  trackId: string;
}

export interface KeyboardEvent extends TrackEvent {
  type: 'keyboard';
  baseName?: string;
  outputCharacter?: string;
  browserCodeKey?: {
    code: string;
    key: string;
  };
  isDown: boolean;
  location: Point;
}

export type PointerEvent = PointerClick | PointerMove | PointerDrag | PointerScroll;

export interface PointerClick extends TrackEvent {
  type: 'click';
  isDown: boolean;
  location: Point;
  button: PointerButton;
}

export interface PointerDrag extends TrackEvent {
  type: 'drag';
  location: Point;
  button: PointerButton;
}

export interface PointerScroll extends TrackEvent {
  type: 'scroll';
  location: Point;
  magnitude: Point;
}

export type PointerButton = 'left' | 'right' | 'back' | 'forward' | 'other';

export interface PointerMove extends TrackEvent {
  type: 'move';
  location: Point;
}
