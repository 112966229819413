import { Point } from 'common/models/geometry.interface';

export type SingleCursorUrlRepresentation = {
  url: string;
  hotspot: Point;
};

export interface CursorUrlRepresentation {
  cursorType: 'single-dpi-aware-representation' | 'multiple-representations';
  singleDpiAwareRepresentation?: SingleCursorUrlRepresentation;
  multipleRepresentations?: SingleCursorUrlRepresentation[];
}

export interface CursorUrlRepresentationSingleDpiAware extends CursorUrlRepresentation {
  cursorType: 'single-dpi-aware-representation';
  singleDpiAwareRepresentation: SingleCursorUrlRepresentation;
}

export interface CursorUrlRepresentationMultiple extends CursorUrlRepresentation {
  cursorType: 'multiple-representations';
  multipleRepresentations: SingleCursorUrlRepresentation[];
}

export function isCursorUrlRepresentationSingleDpiAware(
  cursorRepresentation: CursorUrlRepresentation,
): cursorRepresentation is CursorUrlRepresentationSingleDpiAware {
  return cursorRepresentation.cursorType === 'single-dpi-aware-representation';
}

export function isCursorUrlRepresentationMultiple(
  cursorRepresentation: CursorUrlRepresentation,
): cursorRepresentation is CursorUrlRepresentationMultiple {
  return cursorRepresentation.cursorType === 'multiple-representations';
}
