import autoBind from 'auto-bind';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { MediaType } from 'common/models/connection.interface';
import { OrgId, RemotePeerId, SessionId, UserId } from 'common/models/db/vo.interface';
import { selfPeerId } from 'common/models/db/vo.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { DesktopAppPlatform } from 'common/models/platform.interface';
import { filterIsTruthy } from 'common/utils/custom-rx-operators';
import { toRxjsCallback } from 'common/utils/rxjs-callback';
import { apiCall } from 'pages/vo/vo-react/api';
import { debugWarn } from 'utils/debug-check';
import { FloofAnalyticsDelegate, FloofConnection, FloofConnectionDelegate } from 'utils/floof-sdk/floof-sdk';
import { TransportConfig } from 'utils/floof-sdk/floof-sdk-internal.interface';
import { DualMediaConnection } from 'utils/floof-sdk/media-connection/dual-media-connection';
import { PreferredTransport } from 'utils/floof-sdk/preferred-transport';
import { SessionPeerCountObserver } from 'utils/floof-sdk/session-peer-count-observer';
import { SignalChannel } from 'utils/floof-sdk/signal-channel/signal-channel';
import { generatePeerIdForSession, observeHostnameForSessionId$ } from 'utils/floof-sdk/utils/db';
import { getRegion } from 'utils/floof-sdk/utils/region';

import { AllPeersObserver } from './peer-data/all-peers-observer';
import { SelfPeerBroadcaster } from './peer-data/self-peer-broadcaster';

// Camera and screen sharing are same as v1, but mic now stays up to 4 peers
// (with 5 peers, switch to SFU). Set { mic: 2 } to go back to v1 behavior.
const transportConfig: TransportConfig = {
  maxPeersForP2pForTransportContent: { mic: 4, camera: 2, screen: 2, data: 2 },
};

/**
 * SessionConnectionManager is the director in our media marshalling symphony.
 * It instantiates the pieces of the orchestra (various signalling and media
 * connections) and facilitates the communication between with cues and
 * callbacks. By design, this module does not implement the meat of the logic,
 * but rather delegates to sub-components and gives them the information needed
 * to perform.
 */
export class SessionConnectionManager implements FloofConnection {
  private peerId: RemotePeerId = generatePeerIdForSession(this.sessionId);
  private allPeersObserver = new AllPeersObserver(this.peerId, this.sessionId, {
    onTrack: (...args) => this.delegate.onTrack?.(...args),
    onProvisionalTrack: (...args) => this.delegate.onProvisionalTrack?.(...args),
    onTrackRemoved: (...args) => this.delegate.onTrackRemoved?.(...args),
    getPreferredTransport: (...args) =>
      this.mediaConnection.getPreferredTransportForTransportContents(...args),
  });
  private selfPeerBroadcaster = new SelfPeerBroadcaster(this.peerId, this.sessionId, {
    onTrack: (...args) => this.delegate.onTrack?.('self', ...args),
    onProvisionalTrack: (...args) => this.delegate.onProvisionalTrack?.('self', ...args),
    onTrackRemoved: (...args) => this.delegate.onTrackRemoved?.('self', ...args),
  });
  private signalChannel: SignalChannel = new SignalChannel(this.peerId, this.sessionId, {
    onConnectionStateChanged: (isConnected) => {
      this.mediaConnection.setIsSignalChannelConnected(isConnected);
      if (isConnected) this.delegate.onPeerJoined?.('self');
      else this.delegate.onPeerLeft?.('self');
    },
    onPeerJoined: (peerId) => {
      this.mediaConnection.createP2pMediaConnection(peerId);
      this.allPeersObserver.peerJoined(peerId);
      this.delegate.onPeerJoined?.(peerId);
    },
    onPeerLeft: (peerId) => {
      this.mediaConnection.deleteP2pMediaConnection(peerId);
      this.allPeersObserver.peerLeft(peerId);
      this.delegate.onPeerLeft?.(peerId);
    },
    onP2pGenericSignalingMessage: (peerId, message) =>
      this.mediaConnection.receiveP2pGenericMessage(peerId, message),
    onPeerMessage: (peerId, message) => this.delegate.onMessageReceived?.(peerId, message),
    onSfuNewConsumerRequest: (request, accept, reject) =>
      this.mediaConnection.createSfuConsumer(request, accept, reject),
    onSfuConsumerClosed: (consumerId) => this.mediaConnection.deleteSfuConsumer(consumerId),
    onSfuActiveSpeaker: (peerId) =>
      this.delegate.onActiveSpeakerPeerIdChanged?.(this.maybeTranslateSelfPeerId(peerId)),
  });
  private mediaConnection: DualMediaConnection = new DualMediaConnection(
    this.peerId,
    {
      onTrackSent: (info) => this.selfPeerBroadcaster.setTrackTransportId(info),
      onTrackReceived: (info) => {
        if (info.peerId === this.getSelfPeerId()) {
          debugWarn('Receiving a self track on the media connection is unexpected?');
          return;
        }
        this.allPeersObserver.peerReceivedLocalTrackOnTransport(info);
      },
      onTrackRemoved: (peerId, mediaType, trackId) => {
        console.trace('onTrackRemoved not yet implemented');
        this.delegate.onTrackRemoved?.(peerId, trackId);
      },
      onPeerMessageReceived: (peerId, message) => {
        this.delegate.onMessageReceived?.(peerId, message);
      },
      onRoomSignalChannelMessageRequested: (args) => this.signalChannel?.sendRoomSignalChannelMessage(args),
      onP2pSignalChannelMessageRequested: (message) =>
        this.signalChannel?.sendP2pSignalChannelMessage(message),
      onSfuSignalChannelMessageRequested: (message) =>
        this.signalChannel?.sendSfuSignalChannelMessage(message),
      onDisconnectRequested: () => this.disconnect(true),
    },
    this.analyticsDelegate,
  );
  private preferredTransport = new PreferredTransport(transportConfig, {
    onPreferredTransportChanged: (transportContents, preferredTransport) => {
      this.mediaConnection.setPreferredTransportForTransportContents(transportContents, preferredTransport);
      if (transportContents === 'data') return;
      const mediaType = transportContents as MediaType;
      this.allPeersObserver.setPreferredTransportForMedia({
        mediaType,
        transport: preferredTransport,
      });
    },
  });
  private sessionPeerCountObserver = new SessionPeerCountObserver(this.sessionId, this.peerId, {
    onConnectedPeerCountChanged: (count) => {
      this.preferredTransport.updatePeerCount(count);
    },
  });
  private hostname$ = new BehaviorSubject<string | undefined>(undefined);
  private hostnameObserver = toRxjsCallback({
    obs$: observeHostnameForSessionId$(this.sessionId),
    cb: (hostname) => {
      if (!this.hostname$.value) {
        this.hostname$.next(hostname);
      } else {
        this.delegate.onReconnectionNeeded?.();
      }
    },
  });

  constructor(
    private sessionId: SessionId,
    private delegate: FloofConnectionDelegate,
    private analyticsDelegate?: FloofAnalyticsDelegate,
  ) {
    autoBind(this);
  }

  public async connect({
    displayName,
    avatarUrl,
    userId,
    orgId,
  }: {
    displayName: string;
    avatarUrl?: string;
    userId: UserId;
    orgId: OrgId;
  }) {
    await this.getSessionAccessToken(userId, orgId);
    const hostname = await firstValueFrom(this.hostname$.pipe(filterIsTruthy()));
    this.signalChannel.connect(hostname, { displayName, avatarUrl, userId });
    this.sessionPeerCountObserver.start();
  }

  public getSelfPeerId = () => this.peerId;
  public sendPeerMessage = (peerMessage: PeerMessage) => this.mediaConnection.sendPeerMessage(peerMessage);
  public setSelfMediaTrack = (info: {
    mediaType: MediaType;
    track: MediaStreamTrack | Promise<MediaStreamTrack>;
    deviceId: string;
    scaleFactor?: number;
    platform?: DesktopAppPlatform;
    isEnabled: boolean;
  }) => {
    const logicalTrackId = this.selfPeerBroadcaster.broadcastMediaTrack(info);

    void this.mediaConnection.sendMediaTrack(info);
    return logicalTrackId;
  };

  public setSelfMediaTrackEnabled = (logicalTrackId: string, isEnabled: boolean) => {
    this.selfPeerBroadcaster.setTrackIsEnabled(logicalTrackId, isEnabled);
  };

  public removeSelfMediaTrack = (logicalTrackId: string) => {
    this.selfPeerBroadcaster.withdrawMediaTrack(logicalTrackId);
  };
  public setShouldShowRemoteHdVideo = (opts: { [peerId: string]: boolean; default: boolean }) =>
    this.mediaConnection.setShouldShowRemoteHdVideo(opts);

  public disconnect(isReconnectionNeeded?: boolean) {
    this.sessionPeerCountObserver.disconnect();
    this.signalChannel?.disconnect();
    this.mediaConnection.disconnect();
    this.hostnameObserver.disconnect();
    this.selfPeerBroadcaster.disconnect();
    if (isReconnectionNeeded) this.delegate.onReconnectionNeeded?.();
  }

  private async getSessionAccessToken(userId: UserId, orgId: OrgId) {
    const region = await getRegion();
    await apiCall('getSessionAccessToken', {
      peerId: this.peerId,
      sessionId: this.sessionId,
      mediaServerType: 'floof',
      region,
      userId,
      orgId,
    });
  }

  private maybeTranslateSelfPeerId = (peerId: RemotePeerId) => (peerId === this.peerId ? selfPeerId : peerId);
}
